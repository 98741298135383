import React, { useState } from 'react';
import '../../css/ChatSettingModal.css'
import { useLoginUserState } from '../../contexts/LoginUser';
import voice from '../../api/voice';
import { useOpenRoomStat } from '../../contexts/openRoomContext';
import { useChatState } from '../../contexts/ChatContext';

const ChatSettingModal = ({setIsSettingModal,inviteUserData,updateCheck,setUpdateCheck}) => {


  const [loginUser, setLoginUser] = useLoginUserState();
  const [openRoom, setOpenRoom] = useOpenRoomStat();
  const [chat, setChat] = useChatState();

  const [roomName , setRoomName] = useState('');
  const [roomDesc , setRoomDesc] = useState('');


  const createChannel = async() => {

    let data = {
      userKey: loginUser.userKey,
      userCd : loginUser.userCd,
      "channel": {
        "channel_type_code": 2, //채널 유형을 선택합니다. (1: 1:1채널, 2: 1:N 채널) 
        "channel_name": roomName == '' ? loginUser.name + "외" + String(inviteUserData.length - 1) : roomName ,
        "channel_desc": roomDesc,
        "channel_img_path": "http://test.co.kr/upload/channel/channel_default.png",
        "user_limit": inviteUserData.length //채팅방에 등록할 수 있는 최대 인원 수입니다.
      },
      "users": inviteUserData
    };

    //1대1 대화생성
    if(inviteUserData.length == 2){
      let otherName = inviteUserData[0].nick_name;
      let myId = inviteUserData[inviteUserData.length - 1].user_id;

      let reqData = {
        keyword: otherName,
        user_id: myId,
        userKey : loginUser.userKey,
        userCd : loginUser.userCd
      }
      
      //로그인한 사람의 채팅참여목록
      const res = await voice.loginUserChannels(reqData);
      let chatList = res.data.data;

      //채팅인원2명  (1대1채팅방인거 찾기)
      let filteredChatList = chatList.filter((chat) => {
        return chat.CHAT_USER_CNT === 2;
      });
      
      //가장 최근의 1대1 채팅 
      let recentChat = filteredChatList.sort((a, b) => {
        return b.CHANNEL_PSEQ - a.CHANNEL_PSEQ;
      });
      
      let result = recentChat[0];

      if(result){
        //방이있는경우
        let reqData = {
          roomid : result.CHANNEL_PSEQ,
          roomname : result.CHANNEL_NAME,
          userid : loginUser.userId,
          nickname : loginUser.name,
          usertype : 0,
          langcode : "ko",
          isrequirepwd : false,
          userKey : loginUser.userKey,
          userCd : loginUser.userCd
        }
    
        const tokenAPI = await voice.getToken(reqData);
        if(tokenAPI.status == 200){
          let token = tokenAPI.data.token;
    
          setIsSettingModal(false);
          setOpenRoom(true)
          setChat({
            currentChatRoomId: result.CHANNEL_PSEQ,
            title: result.CHANNEL_NAME,
            members: result.CHAT_USERS,
            token
          });
        }
        return;
      };
    };
    
    const res = await voice.makeChannel(data);

    if(res.data.status == 200){
      let {CHANNEL_PSEQ,CHANNEL_NAME,CHAT_USERS} = res.data.data.channel;

      let reqData = {
        roomid : CHANNEL_PSEQ,
        roomname : CHANNEL_NAME,
        userid : loginUser.userId,
        nickname : loginUser.name,
        usertype : 0,
        langcode : "ko",
        isrequirepwd : false,
        userKey : loginUser.userKey,
        userCd : loginUser.userCd
      };
  
      const tokenAPI = await voice.getToken(reqData);
      if(tokenAPI.status == 200){
        let token = tokenAPI.data.token;
  
        setIsSettingModal(false);
        setOpenRoom(true)
        setChat({
          currentChatRoomId: CHANNEL_PSEQ,
          title: CHANNEL_NAME,
          members: CHAT_USERS,
          token
        });
      };
    };
    setUpdateCheck(!updateCheck);
  };

  return (
    <>
      <div className="blind on"></div>
      <article className="modal-popup popup-chat on">
        <h3 className="pop-title">대화방 개설</h3>
        <div className="plus-friend-search">
          <h4 className="list-title">대화방 이름</h4>
          <div className="input-bar">
            <input type="text" placeholder="대화방 이름을 입력하세요." onChange={(e)=> setRoomName(e.target.value)}/>
          </div>
          <h4 className="list-title">대화방 설명</h4>
          <div className="input-bar">
            <input type="text" placeholder="대화방 설명을 입력하세요." onChange={(e)=> setRoomDesc(e.target.value)}/>
          </div>
        </div>

        <div className="pop-btn-wrap">
          <button type="button" className="btn-line btn-line-gray btn-cancel"
            onClick={()=>{setIsSettingModal(false)}}
          >
            취소
          </button>
          <button type="button" className="btn-line btn-line-sky" onClick={createChannel}>개설</button>
        </div>
      </article>
    </>
  );
};

export default ChatSettingModal;