const generateEnv = (environmentType) => {
  let env = {};

  if (environmentType === "local") {
    env = {
      url: "http://localhost:8097/",
      thumbnail: "http://59.26.112.108",
      mediaURL: "http://59.26.112.108/",
      chatURL : "http://mof.wechatmaker.com:50080",
    };
  } else if(environmentType === "inno"){
    env = {
      url: "http://marine.inno-t.shop/",
      thumbnail: "http://59.26.112.108",
      mediaURL: "http://59.26.112.108/",
      chatURL : "http://mof.wechatmaker.com:50080",
    };
  }else if (environmentType === "dmz1") {
    env = {
      // url: "http://192.168.201.55:8097/",
      // thumbnail: "http://192.168.201.55:8000",
      // mediaURL: "http://192.168.201.55:8000/",
      // chatURL : "http://192.168.201.60:3000/",

      // 1월30일 배포, 2월21일 배포, 3월 20일 배포
      // 외부에서 접속 가능한 url임 
      url: "http://125.128.76.90:8097/",
      thumbnail: "http://125.128.76.90:8000",
      mediaURL: "http://125.128.76.90:8000/",
      chatURL : "http://125.128.76.90:3000",
    };
  } else if (environmentType === "dmz2") {
    env = {
      url: "http://192.168.211.54:8097/",
      thumbnail: "http://192.168.211.54:8000",
      mediaURL: "http://192.168.211.54:8000/",
      chatURL : "http://192.168.211.52:3000",
    };
  } else {
    console.error("Invalid environment type");
  }

  return env;
};

const envType = "inno";  //type 은 local,inno,dmz1,dmz2 
const env = generateEnv(envType);

export default env;