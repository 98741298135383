import React, { useEffect, useState } from 'react';
import { useLoginUserState } from '../../../contexts/LoginUser';
import { useOpenRoomStat } from '../../../contexts/openRoomContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useChatState } from '../../../contexts/ChatContext';
import voice from '../../../api/voice';
import common from '../../common/CommonFunc';
import env from '../../../env';

const ChatItem = ({item,setContentState}) => {

  const [chat, setChat] = useChatState();
  const [loginUser, setLoginUser] = useLoginUserState();

  const [openRoom, setOpenRoom] = useOpenRoomStat();

  const location = useLocation();
  const [pathName, setPathName] = useState('');

  const [profileIMG,setProfileIMG] = useState();

  const navigate = useNavigate();

  useEffect(()=>{
    setPathName(location.pathname);
  },[location]);


  const openPlayRoom = async () => {

    if(!item) setOpenRoom(false);

    let reqData = {
      roomid : item.CHANNEL_PSEQ,
      roomname : item.CHANNEL_NAME,
      userid : loginUser.userId,
      nickname : loginUser.name,
      usertype : 0,
      langcode : "ko",
      isrequirepwd : false,
      userKey : loginUser.userKey,
      userCd : loginUser.userCd
    }

    const tokenAPI = await voice.getToken(reqData);
    if(tokenAPI.status == 200){
      let token = tokenAPI.data.token;

      setChat({
        currentChatRoomId: item.CHANNEL_PSEQ,
        title: item.CHANNEL_NAME,
        members: item.CHAT_USERS,
        token
      });
      setOpenRoom(true)
      navigate(`/chat${pathName.endsWith('2') ? '2' : ''}?userCd=${loginUser.userCd}&regType=${loginUser.type}&userKey=${loginUser.userKey}`);
      setContentState(2);
      
    }
  };

  useEffect(()=>{
    let url = `${env.url}${item.CHANNEL_IMG_PATH}`;
    let convertURL = url.replace(/\\/g, '/');

    common.imageCheck(
      convertURL,
      () => setProfileIMG(`url(${convertURL})`),
      () => setProfileIMG(`url('/images/basic-profile.png')`)
    )
  },[item]);
  

  let totalDate;

  if (item.LOG_DT) {
    let date = new Date(item.LOG_DT);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let hours = date.getHours();
    let minutes = date.getMinutes();

    totalDate = `${year}년 ${month}월 ${day}일 ${hours}시 ${minutes}분`;
  }

  return (
    <div className="talk-list" onClick={openPlayRoom}>
      <a href="#" onClick={(e)=>{e.preventDefault();}}>
        <div className="profile-img">
          <span
            style={{
              background: `${profileIMG} center center no-repeat`
            }}
          ></span>
        </div>
        <div className="talk-thumb">
          <h4>
            {item.CHANNEL_NAME.length >= 14 ? (item.CHANNEL_NAME).substr(0,14)+"..." : item.CHANNEL_NAME}
            <span className="talk-date">{totalDate}</span>
          </h4>
        </div>
      </a>
    </div>
  );
};

export default ChatItem;