import React, { createContext, useContext, useState } from "react";

const LoginUserContext = createContext(null);

export function LoginUserContextProvider({ children }) {
  const [userState, setUserState] = useState({
    // type:null,
    userCd:null,
    userId:null,
    userKey:null,
    name:null,
    stateMSG:null,
    thumbnail:null,
    type:null
  });

  return (
    <LoginUserContext.Provider value={[userState, setUserState]}>
      {children}
    </LoginUserContext.Provider>
  );
}

//use{something}State useState hoook을 만들어주는 함수입니다.
export function useLoginUserState() {
  const userState = useContext(LoginUserContext);
  if (!userState) {
    throw new Error("LoginUserContext is not used");
  }
  return userState;
}
