import "./css/Common.css";
import "./css/fonts.css";
import "./css/basic.css";
import "./css/flex.css";
import { FileContextProvider } from "./contexts/FileContext";
import { OpenRoomContextProvider } from "./contexts/openRoomContext";
import { ChatContextProvider } from "./contexts/ChatContext";
import LoginComponent from "./components/common/LoginComponent";
import { LoginUserContextProvider } from "./contexts/LoginUser";
import { FriendListContextProvider } from "./contexts/FriendListContext";
import { LoadingContextProvider } from "./contexts/LoadingContext";

function App() {

  return (
    <LoginUserContextProvider>
      <ChatContextProvider>
        <FileContextProvider>
          <LoadingContextProvider>
            <OpenRoomContextProvider>
              <FriendListContextProvider>
                <LoginComponent/>
              </FriendListContextProvider>
            </OpenRoomContextProvider>
          </LoadingContextProvider>
        </FileContextProvider>
      </ChatContextProvider>
    </LoginUserContextProvider>
  );
}

export default App;
