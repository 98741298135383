import React, { useEffect, useState } from 'react';
import ListComponent from '../ListComponent';
import ContentComponent from '../ContentComponent';
import ChatCreateModal from '../chat/ChatCreateModal';
import useUserCdEffect from '../common/useUserCdPath';
import { useLoginUserState } from '../../contexts/LoginUser';
import voice from '../../api/voice';
import ChatSettingModal from '../chat/ChatSettingModal';

function ChatComponent({contentState,setContentState,onCheck,setOnCheck}) {

  const [loginUser, setLoginUser] = useLoginUserState();

  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isInviteModal, setIsInviteModal] = useState(false);
  const [isSettingModal, setIsSettingModal] = useState(false);


  const [inviteUserData, setInviteUserData] = useState([]);

  const [updateCheck,setUpdateCheck] = useState(false);

  const [refreshing, setRefreshing] = useState(false);


  // url 에서 userCd가져오기 = 사용자 정보 가져오기
  useUserCdEffect();

  useEffect(()=>{
    setContentState("2");
  },[contentState])

  const [dataList, setDataList] = useState([]);

  const loginUserChannelsAPI = async() => {
    let data = {
      keyword : "",
      user_id : loginUser?.userId,
      userKey : loginUser?.userKey,
      userCd : loginUser?.userCd
    }

    try{
      const res = await voice.loginUserChannels(data);
      let result = res.data.data;

      setDataList(result);
    }catch (error){
      console.error(error);
    }
  };

  
  useEffect(()=>{
    if(loginUser?.userId){
      loginUserChannelsAPI();
    }
  },[loginUser.userCd,updateCheck]);

  

  useEffect(()=>{
    if(refreshing){
      loginUserChannelsAPI();
    }

  },[refreshing]);

  return (
    <>
      {isCreateModal && <ChatCreateModal setIsCreateModal={setIsCreateModal} setIsSettingModal={setIsSettingModal} setInviteUserData={setInviteUserData} updateCheck={updateCheck} setUpdateCheck={setUpdateCheck} />}
      {isSettingModal && <ChatSettingModal setIsSettingModal={setIsSettingModal} inviteUserData={inviteUserData} updateCheck={updateCheck} setUpdateCheck={setUpdateCheck} />}
      <ListComponent 
        dataList={dataList} 
        contentState={contentState} 
        setContentState={setContentState} 
        setIsCreateModal={setIsCreateModal} 
        refreshing={refreshing} 
        setRefreshing={setRefreshing}
        onCheck={onCheck}
        setOnCheck={setOnCheck}/>
      <ContentComponent 
        contentState={contentState} 
        setContentState={setContentState} 
        setIsCreateModal={setIsCreateModal} 
        updateCheck={updateCheck}
        setUpdateCheck={setUpdateCheck}
        />
    </>
  );
}

export default ChatComponent;