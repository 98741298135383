import React, { useState } from 'react';
import UserItem from './UserItem';
import '../../css/FriendBodyComponent.css'

const FriendBodyComponent = ({listData, setDataList , setManagementFriendModal, setAddFriendModal,setTotalSearchModal,setProfile,setProfileModal,setHistoryCheck}) =>{

  const [isBookMarkFolded, setIsBookMarkFolded] = useState(false);
  const [isFriendFolded, setIsFriendFolded] = useState(false);

  const onFold = (type) => {

    if(type === "bookMark"){
      setIsBookMarkFolded(!isBookMarkFolded);
    }
    if(type === "friend"){
      setIsFriendFolded(!isFriendFolded);
    }
  };

  return (
    <>
      <div className="panel-list">
        <h4 className="list-title">나</h4>
        <div className="user-list-wrap">
          {listData.filter((user) => user.userType === "MY_SELF").map((user, index) => {
            return <UserItem 
                      key={index}  
                      user={user}
                      setProfile={setProfile}
                      setManagementFriendModal={setManagementFriendModal} 
                      setAddFriendModal={setAddFriendModal}
                      setTotalSearchModal={setTotalSearchModal}
                      setProfileModal={setProfileModal}
                      setHistoryCheck={setHistoryCheck}
                    />
          })}
        </div>
      </div>
      <div  className="panel-list">
        <h4 className="list-title">즐겨찾기
          <button type="button" className={`btn-fold ${isBookMarkFolded ? 'on' : ''}`} onClick={()=>onFold('bookMark')}>접기</button>
        </h4>
        <div className="user-list-wrap" 
          style={{
            display : 'flex',
            maxHeight: isBookMarkFolded ? 0 : '1000px',
            overflow : 'hidden',
            transition : 'max-height 0.8s ease'
          }}>
          {listData.filter((user)=>user.friendSttsCd === "BOOKMARK" && user.friendSttsCd !== "BLOCK").map((user,index)=>{
            return <UserItem user={user} key={index}
                      setDataList={setDataList}
                      setProfile={setProfile} 
                      setManagementFriendModal={setManagementFriendModal} 
                      setAddFriendModal={setAddFriendModal}
                      setTotalSearchModal={setTotalSearchModal}
                      setProfileModal={setProfileModal}
                      setHistoryCheck={setHistoryCheck}
                    />
          })}

        </div>
      </div>

      <div className="panel-list">
        <h4 className="list-title">친구목록
          <button type="button" className={`btn-fold ${isFriendFolded ? 'on' : ''}`} onClick={()=>onFold('friend')}>접기</button>
        </h4>
        <div className="user-list-wrap"
          style={{
            display : 'flex',
            maxHeight: isFriendFolded ? 0 : '1000px',
            overflow : 'hidden',
            transition : 'max-height 0.8s ease'
          }}>
          {listData.filter((user)=> user.userType === "FRIEND" && user.friendSttsCd !== "BLOCK").map((user,index)=>{
            return <UserItem key={index} user={user}
                    setDataList={setDataList}
                    setProfile={setProfile} 
                    setManagementFriendModal={setManagementFriendModal} 
                    setAddFriendModal={setAddFriendModal}
                    setTotalSearchModal={setTotalSearchModal}
                    setProfileModal={setProfileModal}
                    setHistoryCheck={setHistoryCheck}
                  />
          })}
        </div>
      </div>
    </>
  )
}

export default FriendBodyComponent;