import React, { useState } from 'react';
import voice from '../../../api/voice';
import { useLoginUserState } from '../../../contexts/LoginUser';

const AddRequest = ({addFriendState,
                    setAddRequest,
                    checkState, 
                    setCheckState,
                    setProfileAddCheck,
                    viewCheck,
                    setViewCheck
                  }) => {

  const [loginUser, setLoginUser] = useLoginUserState();
  const [keyword, setKeyWord] = useState("");

  const sendRequest = async() =>{
    setAddRequest(false);
    try {
      //친구요청에 필요한 데이터
      const addData = {
        ...addFriendState,
        dmndMsg : keyword,
        userKey : loginUser.userKey
      }

      let searchListResult = await voice.reqForFriend(addData);
      if(searchListResult.result === "S"){
        setCheckState(!checkState);
        setViewCheck(!viewCheck);

        if(addFriendState?.type == "profileAdd"){
          setProfileAddCheck(true);
        }
      }
    } catch (error) {
      console.error(error);
    };
  };

  return (
    <>
      <div className="blind on"></div>
        <article className="modal-popup popup-friend on">
        <h3 className="pop-title">친구 신청</h3>

        <div className="pop-row">
          <h4 className="list-title">친구</h4>
          <div className="user-list">
            <a href="#" onClick={(e)=>{e.preventDefault();}}>
              <div className="profile-img">
                <span
                  style={{
                    background:addFriendState.filePath
                  }}
                ></span>
              </div>
              <div className="user-name">
                <h4>{addFriendState.userNm}</h4>
                <h2 style={{
                  fontSize:"12px",
                  color:"gray"
                }}>
                {addFriendState.formatCd}</h2>
              </div>
            </a>
          </div>
        </div>

        <div className="pop-row">
          <h4 className="list-title">상태메시지</h4>

          <p className="friend-ment">{addFriendState.userMsg}</p>
        </div>

        <div className="pop-row">
          <label htmlFor="want-ment" className="list-title sky">친구 요청 내용</label>

          <input
            type="text"
            id="want-ment"
            placeholder="메시지를 입력해주세요."
            defaultValue=""
            onChange={(e) => setKeyWord(e.target.value)}
          />
        </div>

        <div className="pop-btn-wrap">
          <button type="button" className="btn-line btn-line-gray btn-cancel"
            onClick={()=>{setAddRequest(false)}}
          >
            취소
          </button>
          <button type="button" className="btn-line btn-line-sky" onClick={sendRequest}>완료</button>
        </div>
      </article>
    </>
  );
};

export default AddRequest;