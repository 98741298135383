import React, { useEffect, useState } from "react";
import "../css/ListHeaderComponent.css";

const ListHeaderComponent = ({listData,
                              replayList,
                              setSearch, 
                              contentState, 
                              setIsCreateModal, 
                              setManagementFriendModal,
                              setAddFriendModal,
                              setTotalSearchModal,
                              onCheck,
                              setHistoryCheck,
                            }) => {
  const [keyword, setKeyWord] = useState("");
  const [isFocus, setIsFocus] = useState(false);

  useEffect(()=>{

  },[contentState])

  const onFocus = (e) => {
    setIsFocus(true);
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  //처음 진입시 검색창 비우기
  useEffect(() => {
    setKeyWord("");
    setSearch("");
    searchFunc();
  }, [contentState,onCheck]);


  useEffect(() => {
    if (keyword == "") {
      if (contentState === "1") {
        setSearch("");
      }
      if (contentState === "2") {
        setSearch("");
      }
      if (contentState === "3") {
        setSearch("");
      }
    }
  }, [keyword]);

  const onSearch = (e) => {
    e.preventDefault();
    searchFunc();
  };

  const searchFunc = () => {
    let result = [];

    if (keyword === "") {
      if(onCheck == "1"){
        setSearch(replayList);
      }

      if(onCheck == "2"){
        setSearch(listData);
      }
    }

    if (contentState === "1") {
      listData.filter((el) => {
        if (keyword != "") {
          if(el.userNm === null) {
            return;
          }

          if (el?.userNm.toLowerCase().includes(keyword.toLowerCase())) {
            result.push(el);
          }
        }
      });
      setSearch(result);
    }
    if (contentState === "2") {
      listData.filter((el) => {
        if (keyword != "") {
          if (el?.CHANNEL_NAME.toLowerCase().includes(keyword.toLowerCase())) {
            result.push(el);
          }
        }
      });
      setSearch(result);
    }

    if (contentState === "3") {

      if(onCheck == "2"){
        let result = [];
        listData.filter((el) => {
          if (keyword != "") {
            if (el.title?.toLowerCase().includes(keyword.toLowerCase())) {
              result.push(el);
            }
          }
        });
        setSearch(result);
      }
      
      if(onCheck == "1"){
        let result = [];
        for (let i = 0; i < replayList?.length; i++) {
          if(replayList[i].filter((it)=>it.name == "title")[0].value.toLowerCase().includes(keyword.toLowerCase())){
            result.push(replayList[i])
          }
        }
        setSearch(result);
      }
    }
  };

  const modalOpen = (type) => {

    if(type === "total"){
      setManagementFriendModal(false);
      setAddFriendModal(false);
      setTotalSearchModal(true);
    };


    if(type === "manage"){
      setAddFriendModal(false);
      setTotalSearchModal(false);
      setManagementFriendModal(true);
    };

    if(type === "add"){
      setManagementFriendModal(false);
      setTotalSearchModal(false);
      setAddFriendModal(true);
    };
    

    if(type === "chat"){
      setIsCreateModal(true);
    }else{
      setHistoryCheck(true)
    };
  };

  return (
    <div className="panel-header">
      <h3 className="panel-title">
        {contentState === "1"
          ? "친구"
          : contentState === "2"
          ? "대화"
          : "음성방송"}

        {contentState === "1" ? (
          <div className="panel-title-btn">
            <button type="button" className="btn-line-icon total-search"
              onClick={()=>{modalOpen('total')}}>
              통합검색
            </button>

            <button type="button" className="btn-line-icon friend-manage"
              onClick={()=>{modalOpen('manage')}}>
              친구관리
            </button>

            <button type="button" className="btn-line-icon friend-plus"
              onClick={()=>{modalOpen('add')}}>
              친구추가
            </button>
          </div>
        ) : contentState === "2" ? (
          <button 
            type="button" 
            className="btn-line-icon chat-plus"
            onClick={()=>{modalOpen('chat')}}
          >
            대화추가
          </button>
        ) : (
          <></>
        )}
      </h3>
      <form action="" onSubmit={(e) => onSearch(e)}>
        <div className="search-bar">
          <input
            type="text"
            placeholder="검색어를 입력하세요."
            value={keyword}
            onChange={(e) => setKeyWord(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          <button
            type="button"
            className={`btn-search ${isFocus && "on"}`}
            onClick={(e)=>{onSearch(e)}}
          ></button>
        </div>
      </form>
    </div>
  );
};

export default ListHeaderComponent;
