import React, { useEffect, useRef, useState } from 'react';
import "../../css/ReplayContentRoomComponent.css";
import { useFileSate } from '../../contexts/FileContext';
import ReplayVodItem from './ReplayVodItem';
import { useOpenRoomStat } from '../../contexts/openRoomContext';
import voice from '../../api/voice';

const ReplayContentRoomComponent = ({audio}) => {

  const [fileState, setFileState] = useFileSate();
  const [openRoom, setOpenRoom] = useOpenRoomStat();

  //vod 안에 있는 컨텐츠
  const [vodList, setVodList] = useState([]);

  const [vodTitle, setVodTitle] = useState('');


  //볼륨 관련
  const [isVolumeBtn, setIsVolumeBtn] = useState(false); //볼륨 설정 모달 관련
  const volumeBarRef = useRef(null);
  const [volumeHeight, setVolumeHeight] = useState("100"); //css 용도
  const [isMute, setIsMute] = useState(false); //음소거 여부

  const [fileID, setFileID] = useState('');
  const [prgTime, setPrgTime] = useState(0); //파일의 총 시간


  let progressContainer = null;

  useEffect(() => {
    if(fileState.type != 1) return;
    const updateProgressBar = () => {
      let percentage = (fileState.playTime / fileState.prgTime) * 100;

      if (percentage > 100) percentage = 100;
      setFileState((prev) => ({
        ...prev,
        progress: percentage,
      }));
    };
    updateProgressBar();
  }, [fileState.playTime]);

  


  const vodListAPI =  async() => {
    const vodListResult = await voice.vodList(fileState.groupId);
    setVodList(vodListResult[0]);
    setVodTitle(vodListResult[1]);
  };

  useEffect(()=>{
    vodListAPI();
  },[fileState.groupId]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isVolumeBtn]);


  //해당 파일 상세보기
  const vodViewAPI = async() =>{
    if(fileID){
      const viewResult = await voice.vodView(fileID);

      viewResult.filter((vod)=>{
        if(vod.name == "playTime"){
          setPrgTime(vod.value);
        };
      });
    };
  };

  useEffect(()=>{
    vodViewAPI();
  },[fileID]);

  //볼륨 컨트롤창 오픈
  const handleVolume = () => {
    if (isVolumeBtn) {
      setIsVolumeBtn(false);
    } else {
      setIsVolumeBtn(true);
    };
  };

  //볼륨 컨트롤 모달창 밖에 클릭시
  const handleClickOutside = (e) => {
    const target = e.target;

    if (isVolumeBtn && !target.closest(".volume-control") && !target.classList.contains("btn-volume")) {
      setIsVolumeBtn(false);
    }
  };

  //볼륨창 마우스 휠 이벤트
  const onUpVolume = (e) => {
    const step = 10;
    const maxVolume = 100;
    const minVolume = 0;

    // 휠 방향 (이거없으면 ↓증가 ↑감소 이렇게됨)
    const direction = e.deltaY > 0 ? 1 : -1;

    // 스텝 수를 계산
    const steps = Math.round(Math.abs(e.deltaY) / step);

    // 볼륨을 새로운 값으로 계산합니다.
    // let newVolume = (volume * 100) - direction * steps;  // 여기에서 변경
    let newVolume = fileState.volume * 100 - direction * steps; // 여기에서 변경

    // 볼륨이 유효한 범위 내에 있는지 확인하고 업데이트합니다.
    newVolume = Math.max(minVolume, Math.min(newVolume, maxVolume));

    //NaN 등등 일때 그냥 0 으로 지정
    if (newVolume === false) {
      newVolume = 0;
    }

    if (!isNaN(newVolume)) {
      //마찬가지로 소수점으로 오디오태그에 넣어줘야함
      setFileState((prev) => ({
        ...prev,
        volume: newVolume / 100,
      }));
      setVolumeHeight(newVolume);
    }

    //음소거 표시
    if (fileState.volume === 0) {
      setIsMute(true);
    } else {
      setIsMute(false);
    }
    // setVolume(fileState.volume);
  };

  // //볼륨 조절
  const updateVolume = (e) => {
    const volumeBar = document.querySelector(".volume-bar");

    const mouseY = volumeBar.clientHeight - (e.pageY - volumeBar.getBoundingClientRect().top);

    // 10단위로 끊어진 값을 계산
    const roundedVolume = Math.round((mouseY / volumeBar.clientHeight) * 10) * 0.1;

    let newVolume = Math.min(1, Math.max(0, roundedVolume));

    //오디오 태그에 들어가는 값은 소수점이라서 소수점으로 적용
    //높이 css 는 곱하기 100

    setFileState((prev) => ({
      ...prev,
      volume: newVolume.toFixed(1),
    }));
    setVolumeHeight(Math.floor(newVolume * 100));


    //음소거 표시
    if (fileState.volume === 0) {
      setIsMute(true);
    } else {
      setIsMute(false);
    }

  };

  //오디오 프로그래스 바
  const handleProgressBarClick = (e) => {
    const clickPosition = e.clientX - progressContainer.getBoundingClientRect().left;
    const percentage = clickPosition / progressContainer.clientWidth;

    let totalTime = prgTime == 0 ? fileState.prgTime : prgTime
    // const newTime = parseInt(percentage * prgTime);
    const newTime = parseInt(percentage * totalTime);
    
    audio.current.seekTo(newTime, 'seconds');
  
    setFileState((prev) => ({
      ...prev,
      playTime: newTime,
    }));
  };

  // 모바일 화면에서 리스트로 이동
  const goList = () => {
    setOpenRoom(false);
  };

  const audioPlay = () => {
    setFileState((prev)=>({
      ...prev,
      play:true,
      type:"1"
    }));
  };

  const audioStop = () => {
    setFileState((prev)=>({
      ...prev,
      play:false,
      type:0
    }));
  };

  return (
    <div className="contents-room">
      <h4 className="contents-title">
        <button type="button" className="btn-icon btn-back" onClick={goList}>목록으로</button>
        {vodTitle}
      </h4>
      <div className="replay-list-wrap">
        <h5 className="replay-title">콘텐츠 목록</h5>
        <div className="replay-contents">
          <ul className="replay-list">

            {vodList.length === 0 && <li><a href="#" onClick={(e)=>{e.preventDefault();}}>컨텐츠 항목이 없습니다.</a></li>}

            {vodList.map((vod,index)=>{
              return <ReplayVodItem vod={vod} key={index} vodList={vodList}/>
            })}
          </ul>
          <div className="play-bar">
            <button type="button" className="btn-icon btn-pause" onClick={audioStop}>일시정지</button>
            <button type="button" className="btn-icon btn-play" onClick={audioPlay}>재생</button>
            <div className="progress-bar"
              onClick={fileState.currentFileID && handleProgressBarClick}
              ref={(el) => (progressContainer = el)}
            >
              <span className="progress" style={{ width: `${isNaN(fileState.progress) ? '0' : fileState.progress}%` }}></span>
            </div>
            <button type="button" 
              className={`btn-icon btn-volume ${isVolumeBtn && "on"} ${isMute && "mute"}`} onClick={handleVolume}>볼륨</button>
            <div className={`volume-control ${isVolumeBtn && "on"}`}>
              <div className="volume-bar"
                ref={volumeBarRef}
                onWheel={(e) => onUpVolume(e)}
                onClick={(e) => updateVolume(e)}
              >
                <span className="volume" style={{ height: volumeHeight + "%" }}></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplayContentRoomComponent;