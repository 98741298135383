import React, { useEffect, useState } from 'react';
import '../../css/TotalSearchModal.css'
import FriendItem from './TotalSearchModal/FriendItem';
import ChatItem from './TotalSearchModal/ChatItem';
import voice from '../../api/voice';
import { useLoginUserState } from '../../contexts/LoginUser';

const TotalSearchModal = ({setTotalSearchModal,setContentState, profile, setProfile,profileModal,setProfileModal}) => {

  const [loginUser, setLoginUser] = useLoginUserState();

  const [keyWord, setKeyWord] = useState('');
  const [isFocus, setIsFocus] = useState(false);

  const [activeMenu, setActiveMenu] = useState('All');

  const [friendList, setFriendList] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [voiceList, setVoiceList] = useState([]); 
  

  const [searchFriendList, setSearchFriendList] = useState(friendList);
  const [searchChatList, setSearchChatList] = useState(chatList);
  const [searchVoiceList, setSearchVoiceList] = useState(voiceList); 


  const goList = () =>{
    setTotalSearchModal(false);
    setProfileModal(false);
  }

  const onFocus = (e) => {
    setIsFocus(true);
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  //현재 메뉴 활성화
  const activeMenuClick = (item) => {
    setActiveMenu(item);
  };


  // 방송 목록 정보 
  const getList = async () => {
    const voiceListResult = await voice.getChannelList();
    const programList = await voice.getProgramList();

    // 채널리스트 및 프로그램 리스트 정보 종합
    const resultList = voiceListResult.map((data) => {
      let resultData = {
        title: "",
        content: "",
        fileID: "",
        prgTime: "",
        thumbnail: "",
      };

      data.forEach((ch) => {
        if (ch.name === "chName") {
          resultData.title = ch.value;
        }

        if (ch.name === "chDesc") {
          resultData.content = ch.value;
        }

        if (ch.name === "httpUrl") {
          resultData.fileID = ch.value;
        }
      });

      if (programList.length > 0) {
        programList.forEach((program) => {
          const programData = program.find(
            (obj) => obj.name === "chName" && obj.value === resultData.title
          );

          if (programData) {
            const prgTime = program.find(
              (obj) => obj.name === "prgTime"
            )?.value;
            const thumbnail = program.find(
              (obj) => obj.name === "thumbnail"
            )?.value;
            resultData.prgTime = prgTime;
            resultData.thumbnail = thumbnail;
          }
        });
      };

      return resultData;
    });

    setVoiceList(resultList);
  };

  //친구 목록 불러오기
  const getFriendListAPI = async() => {

    let regData = {
      userCd : loginUser.userCd,
      regType : loginUser.type,
      userKey : loginUser.userKey
    };

    let getFriendListResult = await voice.getFriendList(regData);

    if(getFriendListResult.result === "S"){
      setFriendList(getFriendListResult.userList);
    };
  };

  //채팅방 목록 불러오기
  const loginUserChannelsAPI = async() => {
    let data = {
      keyword : "",
      user_id : loginUser?.userId,
      userKey : loginUser?.userKey,
      userCd : loginUser?.userCd
    }

    const res = await voice.loginUserChannels(data);
    setChatList(res.data.data);
  };

  useEffect(()=>{
    getList();
    getFriendListAPI();
    loginUserChannelsAPI();
  },[]);

  useEffect(()=>{
    setSearchFriendList("");
    setSearchVoiceList("");
    setSearchChatList("");
  },[keyWord]);

  const onKeyDonwSearch = (e) => {
    if(e.key === "Enter"){
      onSearch(e);
    }
  }

  const onSearch = (e) => {
    e.preventDefault();
  
    let filteredFriendList = [];
    let filteredChatList = [];
    let filteredVoiceList = [];
  
    if (keyWord) {
      filteredFriendList = friendList.filter((user) =>
        user?.userNm.toLowerCase().includes(keyWord.toLowerCase())
      );

      filteredChatList = chatList.filter((chat) =>
        chat?.CHANNEL_NAME.toLowerCase().includes(keyWord.toLowerCase())
      );
  
      filteredVoiceList = voiceList.filter((channel) =>
        channel?.title.toLowerCase().includes(keyWord.toLowerCase())
      );

      setSearchFriendList(filteredFriendList);
      setSearchChatList(filteredChatList);
      setSearchVoiceList(filteredVoiceList);
    };
  };

  let resultFriendList = searchFriendList.length > 0 ? searchFriendList : friendList;
  let resultChatList = searchChatList.length > 0 ? searchChatList : chatList;
  let resultVoiceList = searchVoiceList.length > 0 ? searchVoiceList : voiceList;

  return (
    <div className="contents-room">
      <h4 className="contents-title">
        <button type="button" className="btn-icon btn-back" onClick={goList}>목록으로</button>
        통합 검색
      </h4>

      <div className="search-friend-box">
        <div className="friend-list-search">
          <div className="search-bar">
            <input 
              type="text" 
              placeholder="검색어를 입력하세요." 
              onFocus={onFocus} 
              onBlur={onBlur} 
              onChange={
                (e)=>{ setKeyWord(e.target.value);}
              } 
              onKeyDown={onKeyDonwSearch}/>
            <button 
              type="button" 
              className={`btn-search ${isFocus && "on"}`}
              onClick={(e)=>onSearch(e)}
            ></button>
          </div>
        </div>

        <ul className="lnb">
          <li className={activeMenu === 'All' ? 'on' : ''}    onClick={() => activeMenuClick('All')}>전체</li>
          <li className={activeMenu === 'chat' ? 'on' : ''}   onClick={() => activeMenuClick('chat')}>채팅방</li>
          <li className={activeMenu === 'friend' ? 'on' : ''} onClick={() => activeMenuClick('friend')}>친구</li>
          {/* <li className={activeMenu === 'voice' ? 'on' : ''}  onClick={() => activeMenuClick('voice')}>음성방송</li> */}
        </ul>

        <div className="panel-list-wrap">
          {activeMenu === "All" && 
            <>
              <div className="panel-list">
                <div style={{display:"flex",justifyContent: "space-between"}}>
                  <h4 className="list-title">채팅방</h4>
                  {resultChatList.length > 2 && <button className="list-title" onClick={()=>activeMenuClick('chat')}>더보기</button>}

                </div>
                <div className="talk-list-wrap">
                  {resultChatList.slice(0,2).map((item,index)=>{
                    return <ChatItem key={index} item={item} setContentState={setContentState}/>
                  })}
                </div>
              </div>

              <div className="panel-list">
                <div style={{
                      display:"flex",
                      justifyContent: "space-between"
                    }}>
                  <h4 className="list-title">친구</h4>
                  {resultFriendList.length > 2 && <button className="list-title" onClick={()=>activeMenuClick('friend')}>더보기</button>}
                </div>

                <div className="user-list-wrap">
                  {resultFriendList.filter((user)=> user.userType !== "MY_SELF" && user.friendSttsCd !== "BLOCK").slice(0,2).map((user,index)=>{
                    return <FriendItem 
                              user={user}
                              key={index}
                              profile={profile}
                              setProfile={setProfile}
                              setTotalSearchModal={setTotalSearchModal}
                              profileModal={profileModal}
                              setProfileModal={setProfileModal}
                          />
                  })}
                </div>
              </div>

              {/* <div className="panel-list">
                <div style={{
                      display:"flex",
                      justifyContent: "space-between"
                    }}>
                  <h4 className="list-title">음성방송</h4>
                  {resultVoiceList.length > 2 && <button className="list-title" onClick={()=>activeMenuClick('voice')}>더보기</button>}
                </div>

                <div className="talk-list-wrap">
                  {resultVoiceList.slice(0,2).map((item,index)=>{
                    return <VoiceItem item={item} key={index} setContentState={setContentState}/>
                  })}
                </div>
              </div> */}
            </>
          }

          {activeMenu === "chat" && 
            <div className="panel-list">
              <h4 className="list-title">채팅방</h4>

              <div className="talk-list-wrap">
                {/* <ChatItem />
                <ChatItem /> */}
                {resultChatList.map((item,index)=>{
                  return <ChatItem item={item} key={index} setContentState={setContentState}/>
                })}
              </div>
            </div>
          }


          {activeMenu === "friend" && 
            <div className="panel-list">
              <h4 className="list-title">친구</h4>

              <div className="user-list-wrap">
                {resultFriendList.filter((user)=> user.userType !== "MY_SELF" && user.friendSttsCd !== "BLOCK").map((user,index)=>{
                  return <FriendItem 
                            user={user}
                            key={index}
                            profile={profile}
                            setProfile={setProfile}
                            setTotalSearchModal={setTotalSearchModal}
                            profileModal={profileModal}
                            setProfileModal={setProfileModal}
                          />
                })}
              </div>
            </div>
          }
          
          {/* 20240314 삭제요청 */}
          {/* {activeMenu === "voice" && 
            <div className="panel-list">
              <h4 className="list-title">음성방송</h4>

              <div className="talk-list-wrap">
                {resultVoiceList.map((item,index)=>{
                  return <VoiceItem item={item} key={index} setContentState={setContentState}/>
                })}
              </div>
            </div>
          }  */}
        </div>

        <div className="btn-wrap">
          <button type="button" className="btn-line btn-line-gray btn-cancel cancel-search-all" onClick={goList}>취소</button>
        </div>
      </div>
    </div>
  );
};

export default TotalSearchModal;