import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOpenRoomStat } from '../../contexts/openRoomContext';
import { useLoginUserState } from '../../contexts/LoginUser';
import { useChatState } from '../../contexts/ChatContext';
import voice from '../../api/voice';
import { useFriendState } from '../../contexts/FriendListContext';
import env from '../../env';
import common from '../common/CommonFunc';

const OtherProfile = ({profile,setContentState,setAddRequest,setAddFriendState,profileAddCheck,setProfileModal}) => {

  const [loginUser, setLoginUser] = useLoginUserState();
  const [openRoom, setOpenRoom] = useOpenRoomStat();
  const [friendState, setFriendState] = useFriendState();
  const [chat, setChat] = useChatState();

  const location = useLocation();
  const [pathName, setPathName] = useState('');

  useEffect(()=>{
    setPathName(location.pathname);
  },[location]);

  //프로필 이미지 url
  const [myProfileIMG,setMyProfileIMG] = useState();

  const navigate = useNavigate();

  const friendBlock = async() => {
    let userData = {
      userCd : loginUser.userCd,
      friendCd : profile.userCd,
      friendSttsCd : "NORMAL",
      userKey : loginUser.userKey
    };

    try {
      let result = await voice.uptFriendStts(userData);
      if(result.upCnt > 0){

        let regData = {
          userCd : loginUser.userCd,
          regType : loginUser.type,
          userKey : loginUser.userKey
        };

        let friendListResult = await voice.getFriendList(regData);
        if(friendListResult.result == "S"){
          setFriendState(friendListResult.userList)
          setProfileModal(false);
        };
      };
    } catch (error) {
      console.error(error);
    }
  };

  //친구 신청 모달
  const requestOpen = () =>{
    setAddRequest(true);

    setAddFriendState((prev)=>({
      userNm : profile.userNm,
      userMsg : profile.userMsg,
      userCd: loginUser.userCd,
      toUserCd : profile.userCd,
      filePath : myProfileIMG,
      type:"profileAdd",
      formatCd : profile.formatCd
    }));
  };

 //친구요청 처리
 const proFriendReqAPI = async(type) => {
  let reqData = {
    rspnsCd : type,
    userCd : loginUser.userCd,
    reqUserCd : profile.userCd,
    userKey : loginUser.userKey
  }

  let proFriendReqResult = await voice.proFriendReq(reqData);
  if(proFriendReqResult.data.result == "S"){
    let regData = {
      userCd : loginUser.userCd,
      regType : loginUser.type,
      userKey : loginUser.userKey
    };

    let friendListResult = await voice.getFriendList(regData);
    if(friendListResult.result == "S"){
      setFriendState(friendListResult.userList)
      setProfileModal(false);
    };
  }
};


  useEffect(()=>{
    let url = `${env.url}${profile.fileFlctnPath}/${profile.fileStrgNm}`;
    let convertURL = url.replace(/\\/g, '/');

    common.imageCheck(
      convertURL,
      () => setMyProfileIMG(`url(${convertURL})`),
      () => setMyProfileIMG(`url('/images/basic-profile.png')`)
    )
  },[profile]);


  const chatStart = async() => {
    
    let usersData = [];

    let my = {
      user_id : loginUser.userId,
      nick_name : loginUser.name,
      user_type_code : 1,
      profile_img_path : null,
    };

    let other = {
      user_id : profile.userId,
      nick_name : profile.userNm,
      user_type_code : 0, 
      profile_img_path : null
    }

    usersData.push(other);
    usersData.push(my);
    
    // 채팅방 이름  
    let RoomName = usersData.map((user) => user.nick_name).join(', ');
    // 채팅방 설명  (딱히 정해진것이 없어 이름으로 지정)
    let RoomDesc = usersData.map((user) => user.nick_name).join(', ');

    let data = {
      userKey: loginUser.userKey,
      userCd : loginUser.userCd,
      "channel": {
        "channel_type_code": 2, //채널 유형을 선택합니다. (1: 1:1채널, 2: 1:N 채널) 
        "channel_name": RoomName,
        "channel_desc": RoomDesc,
        "channel_img_path": "http://test.co.kr/upload/channel/channel_default.png",
        "user_limit": usersData.length //채팅방에 등록할 수 있는 최대 인원 수입니다.
      },
      "users": usersData
    };

    let otherName = usersData[0].nick_name;
    let myId = usersData[usersData.length - 1].user_id;

    let reqData = {
      "keyword": otherName,
      "user_id": myId,
      userCd : loginUser.userCd,
      userKey : loginUser.userKey
    }
      
    //로그인한 사람의 채팅참여목록
    const res = await voice.loginUserChannels(reqData);
    let chatList = res.data.data;

    //채팅인원2명  (1대1채팅방인거 찾기)
    let filteredChatList = chatList.filter((chat) => {
      return chat.CHAT_USER_CNT === 2;
    });
    
    //가장 최근의 1대1 채팅 
    let recentChat = filteredChatList.sort((a, b) => {
      return b.CHANNEL_PSEQ - a.CHANNEL_PSEQ;
    });
      
    let alreadyRoom = recentChat[0];

    if(alreadyRoom){
      //방이있는경우
      let alreadyData = {
        roomid : alreadyRoom.CHANNEL_PSEQ,
        roomname : alreadyRoom.CHANNEL_NAME,
        userid : loginUser.userId,
        nickname : loginUser.name,
        usertype : 0,
        langcode : "ko",
        isrequirepwd : false,
        userKey : loginUser.userKey,
        userCd : loginUser.userCd
      }
      getTokenAPI(alreadyData);
    }else{
      const res = await voice.makeChannel(data);

      if(res.data.status == 200){
        let result = res.data.data.channel;
        let newData = {
          roomid : result.CHANNEL_PSEQ,
          roomname : result.CHANNEL_NAME,
          userid : loginUser.userId,
          nickname : loginUser.name,
          usertype : 0,
          langcode : "ko",
          isrequirepwd : false,
          userKey : loginUser.userKey,
          userCd : loginUser.userCd
        }
        getTokenAPI(newData);
      };
    };
    setContentState(2);
    navigate(`/chat${pathName.endsWith('2') ? '2' : ''}?userCd=${loginUser.userCd}&regType=${loginUser.type}&userKey=${loginUser.userKey}`);
  };

  const getTokenAPI = async(data) =>{
    const tokenAPI = await voice.getToken(data);
    if(tokenAPI.status == 200){
      let token = tokenAPI.data.token;
      setChat({
        currentChatRoomId: data.roomid,
        title: data.roomname,
        members: data.CHAT_USERS,
        token
      });
      setOpenRoom(true);
    };
  };
  
  return (
    <article className="profile profile-friend">
    <div
      className="myprofile-img"
      style={{
        background: myProfileIMG
      }}
    >
    </div>

    <div className="profile-cont">
      <div className="cont-top">
        <h2 className="myname">{profile.userNm}</h2>
        {/* 회원 아이디 or 선박 번호 */}
        <h2 className="myname_id" 
          style={{
            fontSize:"12px",
            color:"gray"
          }}>{profile.formatCd}</h2> 
        <p className="friend-ment">{profile.userMsg}</p>
      </div>
      <div className="cont-btn-wrap">
        <button type="button" className="btn-line btn-line-gray btn-cancel"
          onClick={()=>{
            setProfileModal(false);
          }}
        >
          취소
        </button>

          {/* 친구관계일때 */}
          {profile.userType === "FRIEND" && profile.friendSttsCd !== "BLOCK" &&
            <button type="button" className="btn-line btn-line-sky"
              onClick={chatStart}
            >
              1:1 대화 시작
            </button>
          }

          {/* 내가 차단했을 때 */}
          {profile.userType === "FRIEND" && profile.friendSttsCd === "BLOCK" &&
            <button type="button" className="btn-line btn-line-sky"
              onClick={friendBlock}
            >
              차단해제
            </button>
          }

          {/* 친구요청 받았을 때 */}
          { ((profile.userType === "WAITING" || profile.userType == null) && profile.friendSttsCd === "NOT FRIEND") &&
            <>
              <button type="button" className="btn-line btn-line-sky" onClick={()=>proFriendReqAPI("RJCT")}>
                요청 거절
              </button>
              <button type="button" className="btn-line btn-line-sky" onClick={()=>proFriendReqAPI("APRB")}>
                요청 수락
              </button>
            </>
          }

          {/* 친구가 아닐때 */}
          {
            profile.userType === null &&
            profile.friendSttsCd === "N" &&
            profile.dmndRes === "N" &&
            profile.dmndReq === "N" && (
              <button type="button" className="btn-line btn-line-sky" onClick={profileAddCheck ? null : requestOpen}>
                {profileAddCheck ? "친구요청 보냄" : "친구요청"}
              </button>
            )}

          {/* 친구요청 내가 보냄 */}
          {profile.userType === null && profile.dmndReq === "REQ" &&
            <button type="button" className="btn-line btn-line-sky"
              onClick={()=>{
              }}
            >
              친구요청 보냄
            </button>
          }

          {/* 친구요청 보냈는데 상대방이 거절했을때 */}
          {profile.userType === null && profile.dmndReq === "RJCT" &&
            <button type="button" className="btn-line btn-line-sky"
              onClick={()=>{
              }}
            >
              친구요청 보냄
            </button>
          }

          {/* 친구요청이 왔을 때 */}
          {profile.userType === null && profile.dmndRes === "REQ" &&
            <button type="button" className="btn-line btn-line-sky"
              onClick={()=>{
              }}
            >
              친구요청 받음
            </button>
          }
      </div>
    </div>
  </article>
  );
};

export default OtherProfile;