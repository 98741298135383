import React, { useEffect, useState } from 'react';
import ListComponent from '../ListComponent';
import ContentComponent from '../ContentComponent';
import voice from '../../api/voice';
import { useLocation } from 'react-router-dom';
import useUserCdEffect from '../common/useUserCdPath';
import { useFriendState } from '../../contexts/FriendListContext';

function FriendComponent({contentState,setContentState,historyCheck,setHistoryCheck,onCheck,setOnCheck}) {

  const location = useLocation();

  const params = new URLSearchParams(location.search);
  
  const resultUserCd = params.get('userCd');
  const resultRegType = params.get('regType');
  const resultUserKey = params.get('userKey');
  
  const [addFriendModal,setAddFriendModal] = useState(false);
  const [managementFriendModal,setManagementFriendModal] = useState(false);
  const [totalSearchModal, setTotalSearchModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);

  const [profile,setProfile] = useState({
    userCd: null,
    type:null,
    name:null,
    stateMSG:null,
    thumbnail:null
  })

  const [friendState, setFriendState] = useFriendState();

  useUserCdEffect();

  const friendList = async()=>{

    let regData = {
      userCd : resultUserCd,
      regType : resultRegType,
      userKey : resultUserKey
    }

    let friendListResult = await voice.getFriendList(regData);
    if(friendListResult.result == "S"){

      setFriendState(friendListResult.userList)
    };

  };
  useEffect(()=>{
    friendList();
  },[profile])

  const [refreshing, setRefreshing] = useState(false);

  useEffect(()=>{
    if(refreshing){
      friendList();
    }

  },[refreshing])

  useEffect(()=>{
    setContentState("1");
  },[contentState])

  return (
    <>
      <ListComponent 
        dataList={friendState}
        setDataList={setFriendState}
        setProfile={setProfile} 
        contentState={contentState} 
        setContentState={setContentState} 
        setManagementFriendModal={setManagementFriendModal} 
        setAddFriendModal={setAddFriendModal}
        setTotalSearchModal={setTotalSearchModal}
        setProfileModal={setProfileModal}
        refreshing={refreshing}
        setRefreshing={setRefreshing}
        historyCheck={historyCheck}
        setHistoryCheck={setHistoryCheck}
        onCheck={onCheck}
        setOnCheck={setOnCheck}
      />
      <ContentComponent 
        profile={profile}
        setProfile={setProfile}
        contentState={contentState} 
        setContentState={setContentState} 
        addFriendModal={addFriendModal} 
        setAddFriendModal={setAddFriendModal}
        managementFriendModal={managementFriendModal} 
        setManagementFriendModal={setManagementFriendModal} 
        totalSearchModal={totalSearchModal}
        setTotalSearchModal={setTotalSearchModal}
        profileModal={profileModal}
        setProfileModal={setProfileModal}
        historyCheck={historyCheck}
      />
    </>
  );
}

export default FriendComponent;