import React from 'react';
import '../../css/FriendProfile.css'
import MyProfile from './MyProfile';
import OtherProfile from './OtherProfile';

const FriendProfile = ({profile,setProfile, setContentState,setAddRequest,setAddFriendState,profileAddCheck,profileModal,setProfileModal}) => {
  return (
    <div className="contents-room profile-wrap">
      {profile.userCd != null ?
        (profile.userType === "MY_SELF" ?
          <MyProfile profile={profile} setProfile={setProfile} profileModal={profileModal} setProfileModal={setProfileModal}/> :
          <OtherProfile profile={profile} setContentState={setContentState} setAddRequest={setAddRequest} setAddFriendState={setAddFriendState} profileAddCheck={profileAddCheck} setProfileModal={setProfileModal}/>
        )
        : null
      }

    </div>
  );
};

export default FriendProfile;