import axios from "axios";
import env from "../env";
import XMLParser from "react-xml-parser";
import common from "../components/common/CommonFunc";

//공통 API 사용 할 함수입니다.
//baseURL을 입력해주어 모든 axios에서 같은 URL에서 사용 가능합니다.

const client = axios.create({
  baseURL: env.url,
  // headers: {
  //   'USER-MRN': localStorage.getItem("USER-MRN"),
  //   'USER-KEY': localStorage.getItem("USER-KEY"),
  // }
});

const voice = {

  //사용자 키 등록
  // regUserKey : async(reqData) => {
  //   try {
  //     const res = await client.post(`api/regUserKey?userCd=${reqData.userCd}&userKey=${reqData.userKey}`);
  //     return res.data;  
  //   } catch (error) {
  //     console.error(error);
  //   }
  // },

  //사용자 로그인
  requestLogin : async(reqData) => {
    try{
      const res = await client.post(`api/getUserInfo?userCd=${reqData.userCd}`,{},{
        headers: {
          'USER-MRN': reqData.userCd,
          'USER-KEY': reqData.userKey,
        }
      });
      return res.data;      
    }catch(error){
      console.error(error);
    }
  },

  //친구 리스트
  getFriendList : async(reqData) => {
    try{
      const res = await client.post(`api/getFriendList?userCd=${reqData.userCd}&regType=${reqData.regType}`,{},{
        headers: {
          'USER-MRN': reqData.userCd,
          'USER-KEY': reqData.userKey,
        }
      });
      return res.data;      
    }catch(error){
      console.error(error);
    }
  },

  //친구 상태 업데이트 (북마크 추가/해제 및 친구 차단)
  uptFriendStts : async(userData) => {
    try {
      const res = await client.post(`api/uptFriendStts?friendCd=${userData.friendCd}&friendSttsCd=${userData.friendSttsCd}&userCd=${userData.userCd}`,{},{
        headers: {
          'USER-MRN': userData.userCd,
          'USER-KEY': userData.userKey,
        }
      });
      // const res = await client.post(`api/uptFriendStts`,userData);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  //사용자 전체 검색
  searchUserWide : async(reqData) => {
    try {
      const res = await client.post(`api/searchUserWide?userCd=${reqData.userCd}&keyword=${reqData.keyword}&regType=${reqData.regType}`,{},{
        headers: {
          'USER-MRN': reqData.userCd,
          'USER-KEY': reqData.userKey,
        }
      });
      return res.data;
    } catch (error) {
      console.error(error);
    }
  },

  // 친구 요청
  reqForFriend : async(reqData) => {
    try {
      const res = await client.post(`api/reqForFriend?userCd=${reqData.userCd}&toUserCd=${reqData.toUserCd}&dmndMsg=${reqData.dmndMsg}`,{},{
        headers: {
          'USER-MRN': reqData.userCd,
          'USER-KEY': reqData.userKey,
        }
      });
      return res.data;
    } catch (error) {
      console.error(error);
    }
  },

  // 친구 요청 취소 (내가 보낸 요청)
  cancelFriendReq : async(reqData) => {
    try {
      const res = await client.post(`api/cancelFriendReq?userCd=${reqData.userCd}&toUserCd=${reqData.toUserCd}`,{},{
        headers: {
          'USER-MRN': reqData.userCd,
          'USER-KEY': reqData.userKey,
        }
      });
      return res.data;
    } catch (error) {
      console.error(error);
    }
  },

  // 받은 친구 요청 리스트 
  // REQ, RJCT, APRB | rspnsCd 보내지 않을 시 전체 검색
  getFriendReqList : async(reqData) => {
    try {
      const res = await client.post(`api/getFriendReqList?userCd=${reqData.userCd}&rspnsCd=${reqData.rspnsCd}`,{},{
        headers: {
          'USER-MRN': reqData.userCd,
          'USER-KEY': reqData.userKey,
        }
      });
      return res.data;
    } catch (error) {
      console.log(error);
    };
  },

  // 받은 친구 요청 카운트
  newFriendReqCnt : async(reqData) => {
    try {
      const res = await client.post(`api/newFriendReqCnt?userCd=${reqData.userCd}`,{},{
        headers:{
          'USER-MRN': reqData.userCd,
          'USER-KEY': reqData.userKey,
        }
      });
      return res.data;
    } catch (error) {
      console.error(error);  
    }
  },


  //친구요청 처리
  proFriendReq : async(reqData) => {
    try {
      const res = await client.post(`api/proFriendReq?rspnsCd=${reqData.rspnsCd}&userCd=${reqData.userCd}&reqUserCd=${reqData.reqUserCd}`,{},{
        headers:{
          'USER-MRN': reqData.userCd,
          'USER-KEY': reqData.userKey,
        }
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  //프로필 상태메세지 업데이트
  uptProfileMsg : async(reqData) => {
    try {
      const res = await client.post(`api/uptProfileMsg?userMsg=${reqData.userMsg}&userCd=${reqData.userCd}`,{},{
        headers:{
          'USER-MRN': reqData.userCd,
          'USER-KEY': reqData.userKey,
        }
      });
      return res.data;
    } catch (error) {
      console.error(error);
    }
  },

  //프로필 사진 업로드
  profileUpload : async(reqData) => {
    const formData = new FormData();
    formData.append('userCd', reqData.userCd);
    formData.append('file', reqData.file);

    try {
      const res = await client.post(`api/profileUpload`,formData,{
        headers:{
          'USER-MRN': reqData.userCd,
          'USER-KEY': reqData.userKey,
        }
      });
      return res.data;
    } catch (error) {
      console.error(error);
    }
  },

  //프로필 사진
  profile : async(reqData) => {
    try {
      const res = await client.get(`api/profile/image?fileNm=${reqData.fileNm}&fileStrgNm=${reqData.fileStrgNm}`,{
        responseType: "arraybuffer"
      });
      return res.data;
    } catch (error) {
      
      console.error(error);
    }
  },

  // // 음성 방송 채널 리스트 조회
  getChannelList: async() =>
    await client.get(`api/voice/liveList`).then((res) => {
      const xml = new XMLParser().parseFromString(res.data);

      let result = [];
      xml.children.filter((item) => {
        if (item.name == "channelList") {
          let data = item.children;
          data.map((ch) => {
            result.push(ch.children);
          });
        }
      });
      return result;
    }),

  // 음성 방송 프로그램 리스트 조회
  getProgramList: async () =>
    await client.get(`api/voice/programList`).then((res) => {
      const xml = new XMLParser().parseFromString(res.data);

      let result = [];
      xml.children.filter((item) => {
        if (item.name == "programList") {
          let data = item.children;
          data.map((pr) => {
            result.push(pr.children);
          });
        }
      });
    return result;
  }),
 
  getChannelView : async(chId)=>
    await client.get(`api/voice/channelView/${chId}`).then((res) => {
      const xml = new XMLParser().parseFromString(res.data);

      let result = {
        title : null,
        content : null,
        currentFileID : null
      };

      xml.children.filter((item) => {
        if (item.name == "httpUrl") {
          result.currentFileID = item.value;
        };

        if (item.name == "chDesc") {
          result.content = item.value;
        };

        if (item.name == "chName") {
          result.title = item.value;
        }
      });
      return result;
    }),
  

  //vod 전체 리스트 가지고오기
  playList : async() =>{
    try {
      const res = await client.get(`api/voice/vodPlayList`);
      const xml = new XMLParser().parseFromString(res.data);

      let result = [];

      xml.children.forEach((item) => {
        if (item.name === "playlists") {
          result = item.children.map((pr) => pr.children);
        }
      });

      return result;

    } catch (error) {
      console.error(error);
    }
  },

  //live 개수 가지고오기
  liveCnt : async() => {
    try {
      const res = await client.get(`api/voice/liveCnt`);
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  //vod 리스트 가지고오기
  vodList : async (groupId) => {
    try {
      const res = await client.get(`api/voice/playList/${groupId}`);
      const xml = new XMLParser().parseFromString(res.data);

      let result = [];

      xml.children.forEach((item) => {
        
        if(item.name === "title"){
          result.push(item.value)
          }
        
        if(item.name === "vodList"){
          result.push(item.children)
        }
      });
      
      return result;

    } catch (error) {
      console.error(error);
    }
  },

  //vod 파일 상세보기
  vodView : async (fileID) => {
    try {
      const res = await client.get(`api/voice/fileView/${fileID}`);
      const xml = new XMLParser().parseFromString(res.data);
      

      let result = [];

      return result = xml.children;
    } catch (error) {
      console.error(error);
    }
  },
  

  // 오디오 파일 받아오기
  getFile: async (currentFileID) => {
    
    try {
      const rangeStart = 0;
      const rangeEnd = 100000000;

      // 2월21일 배포 당시 headers 값을 추가하면 cors발생
      // 제거하고 요청하여도 음성파일의 range 값 조절이 가능 하였음

      // const headers = new Headers({
      //   'Range': `bytes=${rangeStart}-${rangeEnd}`
      // });

      // const response = await fetch(`${env.mediaURL}rest/stream/${currentFileID}/convert`, {
      //   headers: headers
      // });
      const response = await fetch(`${env.mediaURL}rest/stream/${currentFileID}/convert`);

      const arrayBuffer = await response.arrayBuffer();
      // const blob = new Blob([arrayBuffer]);
      const blob = new Blob([arrayBuffer],{type:'audio/mp4'});
      const url = URL.createObjectURL(blob);
      
      let result = { url , status : "S"}
      
      return result;
  
    } catch (error) {
      console.error('오디오 파일을 가져오는 중 오류 발생:', error);
      return null;
    }
  },

  //생방송 스케줄 체크
  scheduleCheck : async(chId) =>{

    try {

      let date = new Date();
      let year = date.getFullYear(); 
      let month = (date.getMonth() + 1).toString().padStart(2,'0');  
      let day = date.getDate().toString().padStart(2,'0'); 
      let week = date.getDay(); 
      let hours = date.getHours(); 
      let minutes = date.getMinutes(); 
      let seconds = date.getSeconds();  

      
      let fromDate = `${year}-${month}-${day}`;
      let toDate = `${year}-${month}-${day}`;

      let currentTime = `${hours}:${minutes}:${seconds}`;
      // let converCurrentTime = converMs(currentTime);
      let converCurrentTime = common.converMs(currentTime);
   
      const response = await client.get(`api/voice/schedule/check?fromDate=${fromDate}&toDate=${toDate}&chId=${chId}`);

      if(response.status == 200){
        const xml = new XMLParser().parseFromString(response.data);
        let scheduleList = xml.children.filter((item) => item.name == "scheduleList")[0].children;

        let result = {};

        scheduleList.forEach((schedul)=>{

          let originStartTime;
          let originEndTime;
          let startTime;
          let endTime;
          let duration;
          
          schedul.children.forEach((el)=>{
            if(el.name == "startTime"){
              originStartTime = el.value;
              // startTime = converMs(el.value);
              startTime = common.converMs(el.value);
            };

            if(el.name == "endTime"){
              originEndTime = el.value;
              // endTime = converMs(el.value);
              endTime = common.converMs(el.value);
            };

            if(el.name == "duration"){
              duration = el.value;
            };
          });

          if(startTime <= converCurrentTime && endTime >= converCurrentTime){
            result = {
              originStartTime,
              originEndTime,
              startTime,
              endTime,
              duration
            };
          };
        });

        return result;
      };

    } catch (error) {
      console.error(error);
    }
  },



  //채팅 부분

  //로그인한 사용자의 참여 채팅 목록
  loginUserChannels : async(reqData) => {
    try {
      const res = await client.post(`api/chat/channelsByUserId`,reqData,{
        headers: {
          'USER-MRN': reqData.userCd,
          'USER-KEY': reqData.userKey,
        }
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  //채널 생성
  makeChannel : async(reqData) => {
    try {
      const res = await client.post(`api/chat/makeChannel`,reqData,{
        headers:{
          'USER-MRN': reqData.userCd,
          'USER-KEY': reqData.userKey,
        }
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  //채팅 토큰
  getToken : async(reqData) => {

    const formData = new FormData();
    formData.append('roomid', reqData.roomid);
    formData.append('roomname', reqData.roomname);
    formData.append('userid', reqData.userid);
    formData.append('nickname', reqData.nickname);
    formData.append('usertype', reqData.usertype);
    formData.append('langcode', reqData.langcode);
    formData.append('isrequirepwd', reqData.isrequirepwd);

    try {
      const res = await client.post(`api/chat/token`,formData,{
        headers:{
          'USER-MRN': reqData.userCd,
          'USER-KEY': reqData.userKey,
        }
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  //채팅 안읽은 개수 가지고오기
  channelNotyetCnt : async(reqData) =>{
    try {
      const res = await client.post(`api/chat/channelNotyetCnt?userCd=${reqData.user_id}${reqData.channel_pseq ? `&channel_pseq=${reqData.channel_pseq}` : ''}`,{},{
        headers:{
          'USER-MRN': reqData.userCd,
          'USER-KEY': reqData.userKey,
        }
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  },


  //유저 키 재발급
  userKeyRefresh : async(reqData) => {
    try {
      const res = await client.post(`api/ship/userKeyIssued?mrn=${reqData}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }
};

export default voice;
