import React, { useEffect, useState } from 'react';
import env from '../../../env';
import common from '../../common/CommonFunc';

const FriendItem = ({user,setProfile,setTotalSearchModal,setProfileModal}) => {
  //프로필 이미지 url
  const [myProfileIMG,setMyProfileIMG] = useState();

  const openProfile = () => {
    setTotalSearchModal(false)
    setProfileModal(true)
    
    setProfile((prev)=>({
      ...user
    }))
  };

  useEffect(()=>{
    let url = `${env.url}${user.fileFlctnPath}/${user.fileStrgNm}`;
    let convertURL = url.replace(/\\/g, '/');

    common.imageCheck(
      convertURL,
      () => setMyProfileIMG(`url(${convertURL})`),
      () => setMyProfileIMG(`url('/images/basic-profile.png')`)
    )
  },[user]);

  return (
    <div className="user-list">
      <a href="#" onClick={(e)=>{e.preventDefault();}}>
        <div className="profile-img">
          <span
              style={{
                background:
                `${myProfileIMG} center center no-repeat`,
              }}
            ></span>
        </div>
        <div className="user-name" onClick={openProfile}>
          <h4>{user.userNm}</h4>
          <h4 style={{
            fontSize:"12px",
            color:"gray"
          }}>{user.formatCd}</h4>
        </div>
      </a>
    </div>
  );
};

export default FriendItem;