import React, { useEffect, useState } from 'react';
import AddStateItem from './AddModal/AddStateItem';
import { useLoginUserState } from '../../contexts/LoginUser';
import voice from '../../api/voice';

const AddFriendModal = ({
                      setAddFriendModal,
                      setAddRequest,
                      setAddFriendState,
                      checkState, 
                      setCheckState,
                      setProfile,
                      setProfileModal
                    }) => {

  const [keyword, setKeyWord] = useState("");
  const [isFocus, setIsFocus] = useState(false);

  const [loginUser, setLoginUser] = useLoginUserState();
  const [userList, setUserList] = useState([]);
  const [searchList, setSearchList] = useState([]);


  const goList = () =>{
    setAddFriendModal(false);
    setProfileModal(false);
  }

  const onFocus = (e) => {
    setIsFocus(true);
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  const searchListAPI = async(type) => {
    try {
      let reqData = {
        userCd : loginUser.userCd,
        keyword,
        regType : loginUser.type,
        userKey : loginUser.userKey
      }
      let searchListResult = await voice.searchUserWide(reqData);

      if(searchListResult.result === "S"){
        let data = searchListResult.userList.filter((user)=>user.friendSttsCd === "N");
        let searchData = searchListResult.userList.filter((user)=>user.friendSttsCd === "N" && user.dmndReq !== "REQ");
        let addData = searchListResult.userList.filter((user)=>user.friendSttsCd === "N" && user.dmndReq === "REQ");

        if(type == "add"){
          if(keyword){
            setSearchList(searchData);
          }
          setUserList(addData)
        }else{
          if(keyword) {
            setSearchList(searchData);
          }else {
          }
          setUserList(data);
        }


      }
    } catch (error) {
      console.error(error);
    };
  };

  useEffect(()=>{
    searchListAPI("add");
  },[checkState]);

  useEffect(()=>{
    if(keyword === ""){
      setSearchList([]);
      searchListAPI();
    }
  },[keyword]);
  
  
  const onSearch = (e) => {
    e.preventDefault();

    if(keyword === ""){
      setUserList([]);
    };
    searchListAPI();
  };

  return (
    <div className="contents-room">
      <h4 className="contents-title">
        <button type="button" className="btn-icon btn-back" onClick={goList}>목록으로</button>
        친구신청
      </h4>

      <div className="search-friend-box">
        <div className="friend-list-search">
          <form action="" onSubmit={(e)=> onSearch(e)}>
            <div className="search-bar">
              <input 
                type="text" 
                placeholder="검색어를 입력하세요." 
                onFocus={onFocus} 
                onBlur={onBlur}
                value={keyword}
                onChange={(e)=>setKeyWord(e.target.value)}
              />
              <button type="button" className={`btn-search ${isFocus && "on"}`} onClick={(e)=> onSearch(e)}></button>
            </div>
          </form>
        </div>
        
        <div className="panel-list-wrap">
          <div className="panel-list">
            <h4 className="list-title">친구</h4>

            <div className="user-list-wrap">
              {searchList.map((user, index)=>{
                  return <AddStateItem 
                    setProfile={setProfile}
                    setAddFriendModal={setAddFriendModal}
                    checkState={checkState}
                    setCheckState={setCheckState}
                    user={user}
                    key={index}
                    setAddRequest={setAddRequest}
                    setAddFriendState={setAddFriendState}
                    setProfileModal={setProfileModal}
                  />
              })}
            </div>
          </div>

          <div className="panel-list">
            <h4 className="list-title">신청 상태</h4>

            <div className="user-list-wrap">
              {userList.filter((user)=> user.friendSttsCd === "N" && user.dmndReq === "REQ").map((user, index)=>{
                return <AddStateItem 
                          setProfile={setProfile}
                          setAddFriendModal={setAddFriendModal}
                          checkState={checkState}
                          setCheckState={setCheckState}
                          user={user}
                          key={index}
                          setProfileModal={setProfileModal}
                        />
              })}
            </div>
          </div>
        </div>

        <div className="btn-wrap">
          <button type="button" className="btn-line btn-line-gray btn-cancel cancel-friend-plus" onClick={goList}>
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddFriendModal;