import React, { useEffect, useState } from 'react';
import env from '../../env';
import common from '../common/CommonFunc';

const ChatCreateModalItem = ({ user, setCheckMember}) => {

  const [myProfileIMG,setMyProfileIMG] = useState();

  const onCheckUser = (user) => {
    setCheckMember((prev) => {
      if (!prev.includes(user)) {
        return [...prev, user];
      }
      return prev;
    });
  };

  useEffect(()=>{
    let url = `${env.url}${user.fileFlctnPath}/${user.fileStrgNm}`;
    let convertURL = url.replace(/\\/g, '/');

    common.imageCheck(
      convertURL,
      () => setMyProfileIMG(`url(${convertURL})`),
      () => setMyProfileIMG(`url('/images/basic-profile.png')`)
    )
  },[user]);
  
  return (
    <div className="user-list" onClick={()=>{
      onCheckUser(user);
    }}>
      <a href="#" onClick={(e)=>{e.preventDefault();}}>
        <div className="profile-img">
          <span
            style={{
              background: `${myProfileIMG} center center no-repeat`
            }}
          ></span>
        </div>
        <div className="user-name">
          <h4>{user.userNm}</h4>
        </div>
        {user.friendSttsCd == "BOOKMARK" && <button type="button" className={`btn-bookmark ${user.friendSttsCd && "on"}`}>북마크</button>}
      </a>
    </div>
  );
};

export default ChatCreateModalItem;