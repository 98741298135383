import React from 'react';

const SkipNavComponent = ()=> {
  return (
    <div id="skip-Nav">
      <a href="#contents" className="go-main1">본문 바로가기</a>
      <a href="#gnb" className="go-main2">주메뉴 바로가기</a>
    </div>
  );
}

export default SkipNavComponent;