import React, { useEffect, useState } from 'react';
import ManageItem from './ManageMent/ManageItem';
import ManageRequestItem from './ManageMent/ManageRequestItem';
import { useLoginUserState } from '../../contexts/LoginUser';
import voice from '../../api/voice';
import { useFriendState } from '../../contexts/FriendListContext';

const ManagementModal = ({setManagementFriendModal,setProfile,setProfileModal}) => {

  const [loginUser, setLoginUser] = useLoginUserState();
  const [friendState, setFriendState] = useFriendState();

  const [friendList, setFriendList] = useState(friendState);

  const [keyWord, setKeyWord] = useState('');
  const [isFocus, setIsFocus] = useState(false);

  const [originReqList, setOriginReqList] = useState([]);
  const [reqList, setReqList] = useState([]);

  const [reqCheck, setReqCheck] = useState(false);
  const [checkState, setCheckState] = useState(false);

  const goList = () =>{
    setManagementFriendModal(false);
    setProfile({});
    setProfileModal(false);
  }

  const onFocus = (e) => {
    setIsFocus(true);
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  //친구 요청 리스트 가지고오기
  const getFriendReqListAPI = async()=>{
    let reqData = {
      userCd : loginUser.userCd,
      rspnsCd : "REQ",
      userKey : loginUser.userKey
    }

    let getFriendReqListResult = await voice.getFriendReqList(reqData);
    if(getFriendReqListResult.result == "S"){
      setReqList(getFriendReqListResult.userList);
      setOriginReqList(getFriendReqListResult.userList);
    };
  };

  //친구목록 최신화 contextAPI에 세팅
  const recentFriendList = async() =>{

    let regData = {
      userCd : loginUser.userCd,
      regType : loginUser.type,
      userKey : loginUser.userKey
    };

    let friendListResult = await voice.getFriendList(regData);
    if(friendListResult.result == "S"){
      setFriendState(friendListResult.userList)
      setFriendList(friendListResult.userList)
    };
  }

  useEffect(()=>{
    getFriendReqListAPI();
    recentFriendList();
  },[checkState,reqCheck]);


  useEffect(()=>{
    let reqData = reqList;

    if(keyWord === ""){
      setFriendList(friendState);
      setReqList(originReqList);
    }
  },[keyWord]);


  const onKeyDonwSearch = (e) => {
    if(e.key === "Enter"){
      onSearch(e);
    }
  }

  const onSearch = (e) => {
    e.preventDefault();

    let req = [];
    let friend = [];

    if(keyWord){
      reqList.filter((user) => {
        if(user?.userNm.toLowerCase().includes(keyWord.toLowerCase())){
          req.push(user);
        };
      });

      friendState.filter((user)=>{
        if(user?.userNm.toLowerCase().includes(keyWord.toLowerCase())){
          friend.push(user);
        };
      });

      setReqList(req);
      setFriendList(friend)

    };
  };
  

  return (
    <div className="contents-room">
      <h4 className="contents-title">
        <button type="button" className="btn-icon btn-back" onClick={goList}>목록으로</button>
        친구관리
      </h4>

      <div className="search-friend-box">
        <div className="friend-list-search">
          <div className="search-bar">
            <input 
              type="text" 
              placeholder="검색어를 입력하세요." 
              onFocus={onFocus} 
              onBlur={onBlur} 
              onChange={(e)=>setKeyWord(e.target.value)} 
              onKeyDown={onKeyDonwSearch}/>
            <button 
              type="button" 
              className={`btn-search ${isFocus && "on"}`}
              onClick={(e)=>onSearch(e)}
            ></button>
          </div>
        </div>

        <div className="panel-list-wrap">
          <div className="panel-list">
            <h4 className="list-title">친구 요청</h4>

            <div className="user-list-wrap">
              {reqList.filter((user)=> user.rspnsCd === "REQ").map((user,index)=>{
                return <ManageRequestItem 
                          key={index} 
                          user={user}
                          setManagementFriendModal={setManagementFriendModal}
                          setProfile={setProfile}
                          reqCheck={reqCheck}
                          setReqCheck={setReqCheck}
                          setProfileModal={setProfileModal}
                        />
              })}
            </div>
          </div>

          <div className="panel-list">
            <h4 className="list-title">차단 목록</h4>

            <div className="user-list-wrap">
              {friendList.filter((user) => user.userType !== "MY_SELF" && user.friendSttsCd === "BLOCK").map((user,index)=>{
                return <ManageItem 
                          key={index} 
                          user={user} 
                          checkState={checkState} 
                          setCheckState={setCheckState}
                          setManagementFriendModal={setManagementFriendModal}
                          setProfile={setProfile}
                          setProfileModal={setProfileModal}
                        />
              })}
            </div>
          </div>

          <div className="panel-list">
            <h4 className="list-title">친구 목록</h4>

            <div className="user-list-wrap">
              {friendList.filter((user) => user.friendSttsCd === "NORMAL" || user.friendSttsCd === "BOOKMARK").map((user,index)=>{
                return <ManageItem 
                          key={index} 
                          user={user} 
                          checkState={checkState} 
                          setCheckState={setCheckState}
                          setManagementFriendModal={setManagementFriendModal}
                          setProfile={setProfile}
                          setProfileModal={setProfileModal}
                        />
              })}
            </div>
          </div>
        </div>

        <div className="btn-wrap">
          <button type="button" className="btn-line btn-line-gray btn-cancel cancel-friend-manage" onClick={goList}>취소</button>
        </div>
      </div>
    </div>
  );
};

export default ManagementModal;