import React from "react";
import "../../css/ListItem.css";
import { useFileSate } from "../../contexts/FileContext";
import { useOpenRoomStat } from "../../contexts/openRoomContext";
import voice from "../../api/voice";
import env from "../../env";


const ListItem = ({ data }) => {
  const [fileState, setFileState] = useFileSate();
  const [openRoom, setOpenRoom] = useOpenRoomStat();

  const openPlayRoom = async () => {
    if(data.status == '1') return;
    setFileState({}); //vod 에서 생방송 넘어올때 state 초기화
    
    if (data.fileID) {
      const response = await voice.scheduleCheck(data.chId);
      
      if(response){

        let startTime = parseInt(response.startTime);

        setOpenRoom(true);
        setFileState((prev) => ({
          ...prev,
          chId : data.chId,
          title: data.title,
          content: data.content,
          currentFileID: (data.fileID).replace('192.168.201.55','125.128.76.90'), 
          prgTime: parseInt(response.duration),
          thumbnail : data.thumbnail,
          groupId : '',
          type: '2',
          volume:1,
          vodFileId : null,
          play:false,
          status:data.status, // 1 : 실시간off   2: 실시간On
          startTime, // 생방송 시작시간
          playTime: 0,
          progress: 0,
        }));
      }

    } else {
      setOpenRoom(false);
    }
  };

  let backgroudnURL = data.thumbnail ? `url(${env.thumbnail}${data.thumbnail})` : `url(/images/basic-profile.png)`; //dmz1 new 테스트
  return (
    <div className="talk-list" onClick={openPlayRoom}>
      <a href="#" onClick={(e)=>{e.preventDefault();}}>
        {data.status === "2" && <span className="live">실시간</span>}
        <div className="profile-img">
          <span
            style={{ background: `${backgroudnURL} center center no-repeat`}}
          ></span>
        </div>
        <div className="talk-thumb">
          <h4>{data.title}</h4>
          <p>{data.content}</p>
        </div>
      </a>
    </div>
  );
};

export default ListItem;
