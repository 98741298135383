import React from 'react';
import { useOpenRoomStat } from '../../contexts/openRoomContext';
import { useFileSate } from '../../contexts/FileContext';

const ReplayListItem = ({item}) => {
  const [fileState, setFileState] = useFileSate();
  const [openRoom, setOpenRoom] = useOpenRoomStat();

  const openPlayRoom = async () => {
    setOpenRoom(true);

    setFileState((prev) => ({
      ...prev,
      content: null,
      currentFileID : null,
      groupId:item.filter((it)=>it.name === "groupId")[0].value,
      play: false,
      playTime: 0,
      prgTime: 0,
      progress: 0,
      thumbnail: null,
      title: null,
      type: 0,
      volume: 1,
      status:0
    }));
  };

  return (
    <div className="talk-list" onClick={openPlayRoom}>
      <a href="#" data-target=".contents-wrap" onClick={(e)=>{e.preventDefault();}}>
        <div className="profile-img">
          <span style={{background: "url(/images/basic-profile.png) center center no-repeat"}}></span>
        </div>
        <div className="talk-thumb">
          <h4>{item.filter((it)=>it.name === "title")[0].value}</h4>
        </div>
      </a>
    </div>
  );
};

export default ReplayListItem;