import React from 'react';
import ReplayListItem from './ReplayListItem';


const ReplayList = ({replayList}) => {
  return (
    <div className="panel-list">
      <h4 className="list-title">방송목록</h4>

      <div className="talk-list-wrap">
        {replayList?.map((item,index)=>{
          return <ReplayListItem item={item} key={index}/>
        })}
      </div>
    </div>
  );
};

export default ReplayList;