import React, { useEffect, useState } from "react";
import "../css/ListBottomComponent.css";
import { useOpenRoomStat } from "../contexts/openRoomContext";
import { Link, useLocation } from "react-router-dom";
import { useLoginUserState } from "../contexts/LoginUser";
import voice from "../api/voice";

const ListBottomComponent = ({ contentState, setContentState}) => {
  const [openRoom, setOpenRoom] = useOpenRoomStat();
  const [loginUser] = useLoginUserState();

  const location = useLocation();
  const [pathName, setPathName] = useState('');

  const [reqFriendCnt, setReqFriendCnt] = useState();
  const [notYetChat, setNotYetChat] = useState(0);
  const [liveCnt, setLiveCnt] = useState(0);

  useEffect(()=>{
    setPathName(location.pathname);
  },[location]);


  //친구요청 받은 카운트
  const reqFriendAPI = async() =>{
    let data ={
      userCd : loginUser.userCd,
      userKey : loginUser.userKey
    };

    const req = await voice.newFriendReqCnt(data);
    setReqFriendCnt(req.cnt)
  };

  const notYetChatCnt = async() => {
    let reqData = {
      "keyword": null,
      "user_id": loginUser.userId,
      "channel_pseq": null,
      userKey : loginUser.userKey,
      userCd : loginUser.userCd
    };

    if(loginUser.userId){
      try {
        const res = await voice.channelNotyetCnt(reqData);
        setNotYetChat(res.data.cnt);

      } catch (error) {
        console.error(error);        
      };
    };
  };

  const liveCntAPI = async() => {
    const res = await voice.liveCnt();
    if(res.status === 200) {
      setLiveCnt(res.data.cnt);
    };
  };

  useEffect(() => {
    if (loginUser.userCd) {
      reqFriendAPI();
      notYetChatCnt();
      liveCntAPI();
  
      const intervalId = setInterval(() => {
        reqFriendAPI();
        notYetChatCnt();
        liveCntAPI();
      }, 10000);
  
      return () => {
        clearInterval(intervalId); 
      };
    }
  }, [loginUser, contentState]); 


  const contentChange = (num) => {
    setContentState(num);
    setOpenRoom(false);
  };

  return (
    <>
      {
        pathName == '/voice2' ? 
          <></> :
          <nav className="gnb-wrap" id="gnb">
            <ul>
              <li className={`${contentState == "1" && "on"}`} onClick={()=>{contentChange('1')}}>
                <Link replace to={`/friend${pathName.endsWith('2') ? '2' : ''}?userCd=${loginUser.userCd}&regType=${loginUser.type}&userKey=${loginUser.userKey}`} className="gnb01" >
                  <span className="gnb-icon">
                    {reqFriendCnt > 0 && <i className="talk-num">{reqFriendCnt}</i>}
                  </span>
                  친구
                </Link>
              </li>
              <li className={`${contentState == "2" && "on"}`} onClick={()=>{contentChange('2')}}>
                <Link replace to={`/chat${pathName.endsWith('2') ? '2' : ''}?userCd=${loginUser.userCd}&regType=${loginUser.type}&userKey=${loginUser.userKey}`} className="gnb02">
                    <span className="gnb-icon">
                      {notYetChat > 0 && <i className="talk-num">{notYetChat}</i>}
                    </span>
                    대화
                </Link>
              </li>
              {pathName.endsWith('2') == false && 
                <li className={`${contentState == "3" && "on"}`} onClick={()=>{contentChange('3')}}>
                  <Link replace to={`/voice?userCd=${loginUser.userCd}&regType=${loginUser.type}&userKey=${loginUser.userKey}`} className="gnb03">
                    <span className="gnb-icon">
                      {liveCnt > 0 && <i className="talk-num">{liveCnt}</i>}
                    </span>
                    음성방송
                  </Link>
                </li>
              } 
            </ul>
          </nav>
      }
    </>
  );
};

export default ListBottomComponent;
