import React, { useEffect, useRef, useState } from "react";
import "../../css/ContentRoomComponent.css";
import { useFileSate } from "../../contexts/FileContext";
import { useOpenRoomStat } from "../../contexts/openRoomContext";
import env from "../../env";

const ContentRoomComponent = () => {
  const [fileState, setFileState] = useFileSate(); // 음성룸 정보
  const [openRoom, setOpenRoom] = useOpenRoomStat();

  const [isVolumeBtn, setIsVolumeBtn] = useState(false); //볼륨 설정 모달 관련
  const volumeBarRef = useRef(null);
  const [volumeHeight, setVolumeHeight] = useState("100"); //css 용도

  const [isMute, setIsMute] = useState(false); //음소거 여부

  const [temp,setTemp] = useState(''); //실시간 방송정지시 currentFileID 백업용도 정지시 null를 하기 때문에

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isVolumeBtn]);

  //볼륨 컨트롤창 오픈
  const handleVolume = () => {
    if (isVolumeBtn) {
      setIsVolumeBtn(false);
    } else {
      setIsVolumeBtn(true);
    };
  };

  const handleClickOutside = (e) => {
    const target = e.target;

    if (isVolumeBtn && !target.closest(".volume-control") && !target.classList.contains("btn-volume")) {
      setIsVolumeBtn(false);
    };
  };

  // //볼륨 조절
  const updateVolume = (e) => {
    const volumeBar = document.querySelector(".volume-bar");

    const mouseY = volumeBar.clientHeight - (e.pageY - volumeBar.getBoundingClientRect().top);

    // 10단위로 끊어진 값을 계산
    const roundedVolume = Math.round((mouseY / volumeBar.clientHeight) * 10) * 0.1;

    let newVolume = Math.min(1, Math.max(0, roundedVolume));

    //오디오 태그에 들어가는 값은 소수점이라서 소수점으로 적용
    //높이 css 는 곱하기 100

    setFileState((prev) => ({
      ...prev,
      volume: newVolume.toFixed(1),
    }));
    setVolumeHeight(Math.floor(newVolume * 100));


    //음소거 표시
    if (fileState.volume === 0) {
      setIsMute(true);
    } else {
      setIsMute(false);
    };
  };

  //볼륨창 마우스 휠 이벤트
  const onUpVolume = (e) => {
    const step = 10;
    const maxVolume = 100;
    const minVolume = 0;

    // 휠 방향 (이거없으면 ↓증가 ↑감소 이렇게됨)
    const direction = e.deltaY > 0 ? 1 : -1;

    // 스텝 수를 계산
    const steps = Math.round(Math.abs(e.deltaY) / step);

    // 볼륨을 새로운 값으로 계산합니다.
    // let newVolume = (volume * 100) - direction * steps;  // 여기에서 변경
    let newVolume = fileState.volume * 100 - direction * steps; // 여기에서 변경

    // 볼륨이 유효한 범위 내에 있는지 확인하고 업데이트합니다.
    newVolume = Math.max(minVolume, Math.min(newVolume, maxVolume));

    //NaN 등등 일때 그냥 0 으로 지정
    if (newVolume === false) {
      newVolume = 0;
    }

    if (!isNaN(newVolume)) {
      //마찬가지로 소수점으로 오디오태그에 넣어줘야함
      setFileState((prev) => ({
        ...prev,
        volume: newVolume / 100,
      }));
      setVolumeHeight(newVolume);
    };

    //음소거 표시
    if (fileState.volume === 0) {
      setIsMute(true);
    } else {
      setIsMute(false);
    };
  };

  // 모바일 화면에서 리스트로 이동
  const goList = () => {
    setOpenRoom(false);
  };

  const audioPlay = () => {
    setFileState((prev)=>({
      ...prev,
      play:true,
      currentFileID : !temp ?  fileState.currentFileID : temp,
      type:"2"
    }));
  };

  const audioStop = () => {

    setTemp(fileState.currentFileID);

    setFileState((prev)=>({
      ...prev,
      play:false,
      currentFileID: fileState.type == "2" ? null : fileState.currentFileID,
      type:0,
    }));
  };

  let backgroudnURL = fileState.thumbnail ? `url(${env.thumbnail}${fileState.thumbnail})` : `url(/images/basic-profile.png)`; //테스트

  return (
    <div className="contents-room">
      <h4 className="contents-title">
        <button type="button" className="btn-icon btn-back" onClick={goList}>
          목록으로
        </button>
        해양수산부
      </h4>
      <div className="play-card">
        <div className="card-profile">
          <span
            style={{
              background: `${backgroudnURL} center center no-repeat`,
            }}
          ></span>
        </div>
        <div className="card-contents">
          <h3>{fileState.title}</h3>
          <p>{fileState.content}</p>
          <div className="play-bar no-play">
            <button
              type="button"
              className="btn-icon btn-pause"
              onClick={fileState.status == "2" ? audioStop : undefined}
            >
              일시정지
            </button>
            <button
              type="button"
              className="btn-icon btn-play"
              // onClick={ audioPlay }
              onClick={fileState.status == "2" ? audioPlay : undefined}
            >
              재생
            </button>
            <div className="progress-bar">
              <span className="progress"></span>
            </div>
            <button
              type="button"
              className={`btn-icon btn-volume ${isVolumeBtn && "on"} ${
                isMute && "mute"
              }`}
              onClick={handleVolume}
            >
              볼륨
            </button>
            <div className={`volume-control ${isVolumeBtn && "on"}`}>
              <div
                className="volume-bar"
                ref={volumeBarRef}
                onWheel={(e) => onUpVolume(e)}
                onClick={(e) => updateVolume(e)}
              >
                <span
                  className="volume"
                  style={{ height: volumeHeight + "%" }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentRoomComponent;
