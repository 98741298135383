import React, { useEffect, useState } from 'react';
import '../../css/UserItem.css'
import { useOpenRoomStat } from '../../contexts/openRoomContext';
import voice from '../../api/voice';
import { useLoginUserState } from '../../contexts/LoginUser';
import { useFriendState } from '../../contexts/FriendListContext';
import env from '../../env';
import common from '../common/CommonFunc';

const UserItem = ({user, setDataList, setManagementFriendModal, setAddFriendModal, setTotalSearchModal,setProfile,setProfileModal,setHistoryCheck}) => {
  
  const [loginUser, setLoginUser] = useLoginUserState();
  const [openRoom,setOpenRoom] = useOpenRoomStat();

  const [userData,setUserData] = useState();
  const [isBookMark, setIsBookMark] = useState(null);

  const [friendState, setFriendState] = useFriendState();

  const [myProfileIMG,setMyProfileIMG] = useState();
  
  useEffect(()=>{
  
    setIsBookMark(user.friendSttsCd == "BOOKMARK")

    setUserData(user);
  },[user]);

  useEffect(()=>{
    let url = `${env.url}${user.fileFlctnPath}/${user.fileStrgNm}`;
    let convertURL = url.replace(/\\/g, '/');

    common.imageCheck(
      convertURL,
      () => setMyProfileIMG(`url(${convertURL})`),
      () => setMyProfileIMG(`url('/images/basic-profile.png')`)
    )
  },[user]);

  const checkBookMark = (friendCd) =>{

    let friendState = isBookMark ? "NORMAL" : "BOOKMARK";

    try {
      const uptFriendStts = async () =>{

        let userData = {
          userCd : loginUser.userCd,
          friendCd : friendCd,
          friendSttsCd : friendState,
          userKey : loginUser.userKey
        };
  
        let result = await voice.uptFriendStts(userData);
        if(result.upCnt > 0){
          setIsBookMark(!isBookMark);

          let regData = {
            userCd : loginUser.userCd,
            regType : loginUser.type,
            userKey : loginUser.userKey
          };

          const updatedDataList = await voice.getFriendList(regData);
          if (updatedDataList.result === "S") {
            setDataList(updatedDataList.userList);
            setFriendState(updatedDataList.userList)
          };
        };
      };
      uptFriendStts();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="user-list">
      <a href="#" onClick={(e)=>{e.preventDefault();}}>
        <div className="profile-img">
          <span
            style={{
              background: `${myProfileIMG} center center no-repeat`
              
            }}
          ></span>
        </div>
        <div className="user-name"
          onClick={()=>{
            setHistoryCheck(true);
            setProfileModal(true);
            setManagementFriendModal(false);
            setAddFriendModal(false);
            setTotalSearchModal(false);

            setProfile((prev)=>({
              ...user,
            }));

            // 프로필 상태메세지 변경시 키보드올라왔을때
            (document.querySelector('.contents-wrap.contents-wrap2.friend').style.height = "100%");
            
          }}
        >
          <h4>{userData?.userNm}</h4>
          <h4 style={{
            fontSize:"14px",
            color:"gray"
          }}>{userData?.formatCd}</h4>
        </div>
        {userData?.userType !== "MY_SELF" ? 
          <button type="button" className={`btn-bookmark ${isBookMark ? 'on' : ''}`} onClick={()=>checkBookMark(user.userCd)}>북마크</button>:  ""}
      </a>
    </div>
  );
};

export default UserItem;