import React from 'react';

function NotFound(props) {
  return (
    <div
    style={{
      display:"block",
      textAlign:"center",
      widows:"300px",
      height:"300px",
      position:"absolute",
      left:"50%",
      top:"50%",
      marginLeft:"-150px",
      marginTop:"-50px",
      fontSize:"24px",
      fontWeight:"bold"
      
    }}>
      인증이 필요한 사용자 입니다.
    </div>
  );
}

export default NotFound;