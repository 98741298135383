import React, { createContext, useContext, useState } from "react";

const LoadingContext = createContext(null);

// {something}ContextProvider 만들어주는 함수입니다.
export function LoadingContextProvider({ children }) {
  const [loadingState, setLoadingState] = useState(false);

  return (
    <LoadingContext.Provider value={[loadingState, setLoadingState]}>
      {children}
    </LoadingContext.Provider>
  );
}

// use{something}State useState hook을 만들어주는 함수입니다.
export function useLoadingState() {
  const loadingState = useContext(LoadingContext);

  if (!loadingState) {
    throw new Error("LoadingContext is not used");
  }

  return loadingState;
}
