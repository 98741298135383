import { useChatState } from "../../contexts/ChatContext";
import "../../css/ChatRoomComponent.css";
import { useOpenRoomStat } from "../../contexts/openRoomContext";
import env from "../../env";

const ChatRoomComponent = ({updateCheck,setUpdateCheck}) => {
  const [chat, setChat] = useChatState();

  const [openRoom,setOpenRoom] = useOpenRoomStat();

  const pushBack = () => {
    setChat({
      currentChatRoomId: null,
      title: null,
      members: null,
    });

    setOpenRoom(false);
  };

  //채팅 iframe 이벤트 (채팅방 나가기, 채팅방 이름 변경)
  window.addEventListener("message", function(e) {
    if(e.origin == env.chatURL){
      if(e.data.status == "close"){
        setOpenRoom(false);
        setUpdateCheck(!updateCheck);
      };

      if(e.data.status == "update_channel_name"){
        setChat((prev)=>({
          ...prev,
          title:e.data.channel_name
        }));
        setUpdateCheck(!updateCheck)
      };

    };
  });


  return (
    <>
      <div className="contents-bg"></div>
      <div className="contents-room" style={{ alignItems: "start" }}>
        <h4 className="contents-title">
            <button
              type="button"
              className="btn-icon btn-back"
              onClick={pushBack}
            >
              목록으로
            </button>
            {chat.title}
            <span className="chat-member">{chat.CHAT_USER_CNT}</span>
            <div className="title-btn-wrap"></div>
          </h4>

            <div style={{
              height :'99%',
              width : '100%',
              background:'white'
            }}>
            <iframe src={`${env.chatURL}/?token=${chat?.token}`} 
              id="chat_iframe"
              style={{
                width : '100%' ,
                height : '100%'
              }}
            />
            </div>
      </div>
    </>
  );


};

export default ChatRoomComponent;
