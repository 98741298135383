import React, { useEffect, useState } from 'react';
import '../../css/ChatCreateModal.css'
import ChatCreateModalItem from './ChatCreateModalItem';
import { useFriendState } from '../../contexts/FriendListContext';
import voice from '../../api/voice';
import { useLoginUserState } from '../../contexts/LoginUser';
import { useOpenRoomStat } from '../../contexts/openRoomContext';
import { useChatState } from '../../contexts/ChatContext';


const ChatCreateModal = ({setIsCreateModal,setIsSettingModal,updateCheck,setUpdateCheck}) => {

  const [keyword, setKeyWord] = useState("");

  const [chat, setChat] = useChatState();
  const [openRoom, setOpenRoom] = useOpenRoomStat();

  const [checkMember,setCheckMember] = useState([]);

  const [loginUser, setLoginUser] = useLoginUserState();

  const [myFriendList, setMyFriendList] = useFriendState();
  const [searchList, setSearchList] = useState([]);

  const [isFocus, setIsFocus] = useState(false);

  
  useEffect(()=>{
    const friendList = async()=>{

      let regData = {
        userCd : loginUser.userCd,
        regType : loginUser.type,
        userKey : loginUser.userKey
      };

      let friendListResult = await voice.getFriendList(regData);
      if(friendListResult.result == "S"){
        setMyFriendList(friendListResult.userList)
      };
    };
    friendList();
  },[loginUser.userCd])


  useEffect(() => {
    if (keyword == "") {
      setSearchList(myFriendList);
    }
  }, [keyword]);

  //검색창 포커스
  const onFocus = (e) => {
    setIsFocus(true);
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  // 리스트 인원 제거
  const removeCheckMember = (userToRemove) => {
    setCheckMember((prevCheckMember) => {
      const updatedCheckMember = prevCheckMember.filter((user) => user !== userToRemove);
      return updatedCheckMember;
    });
  };

  const onSearch = (e) => {
    e.preventDefault();
    searchFunc();
  };

  const searchFunc = () => {
    let result = [];

    if (keyword === "") {
      setSearchList(myFriendList);
    }

      myFriendList.filter((el) => {
        if (keyword != "") {
          if (el?.userNm.toLowerCase().includes(keyword.toLocaleLowerCase())) {
            result.push(el);
          }
        }
      });
      setSearchList(result);
  };

  //검색 결과에 따라 데이터 리스트 변경
  let renderList = searchList.length === 0 ? myFriendList : searchList;

  const createChatRoom = async(memberList) =>{
    if(checkMember.length == 0) return;

    //사용자 정보
    let my = {
      user_id : loginUser.userId,
      nick_name : loginUser.name,
      user_type_code : 1,
      profile_img_path : null,
    };

    //초대사용자들
    let usersData = memberList.map((member)=>{
      let result = {
        user_id : null,
        nick_name : null,
        user_type_code : 0, //사용자 구분코드입니다. (0: 사용자, 1: 관리자) 채널을 개설하는 사용자에게 1, 나머지 사용자들은 0으로 입력합니다.
        profile_img_path : null
      };

      result.user_id = member.userId;
      result.nick_name = member.userNm;

      return result;
    });

    //사용자 + 채팅 초대자 데이터 합치기
    usersData.push(my);

    // setInviteUserData(usersData);
    setIsCreateModal(false);
    // setIsSettingModal(true);

    let RoomName = usersData.map((user) => user.nick_name).join(', ');

    let data = {
      userKey: loginUser.userKey,
      userCd : loginUser.userCd,
      "channel": {
        "channel_type_code": 2, //채널 유형을 선택합니다. (1: 1:1채널, 2: 1:N 채널) 
        "channel_name": RoomName,
        "channel_desc": "",
        "channel_img_path": "http://test.co.kr/upload/channel/channel_default.png",
        "user_limit": usersData.length //채팅방에 등록할 수 있는 최대 인원 수입니다.
      },
      "users": usersData
    };

    //1대1 대화생성
    if(usersData.length == 2){
      let otherName = usersData[0].nick_name;
      let myId = usersData[usersData.length - 1].user_id;

      let reqData = {
        keyword: otherName,
        user_id: myId,
        userKey : loginUser.userKey,
        userCd : loginUser.userCd
      }
      
      //로그인한 사람의 채팅참여목록
      const res = await voice.loginUserChannels(reqData);
      let chatList = res.data.data;

      //채팅인원2명  (1대1채팅방인거 찾기)
      let filteredChatList = chatList.filter((chat) => {
        return chat.CHAT_USER_CNT === 2;
      });
      
      //가장 최근의 1대1 채팅 
      let recentChat = filteredChatList.sort((a, b) => {
        return b.CHANNEL_PSEQ - a.CHANNEL_PSEQ;
      });
      
      let result = recentChat[0];

      if(result){
        //방이있는경우
        let reqData = {
          roomid : result.CHANNEL_PSEQ,
          roomname : result.CHANNEL_NAME,
          userid : loginUser.userId,
          nickname : loginUser.name,
          usertype : 0,
          langcode : "ko",
          isrequirepwd : false,
          userKey : loginUser.userKey,
          userCd : loginUser.userCd
        }
    
        const tokenAPI = await voice.getToken(reqData);
        if(tokenAPI.status == 200){
          let token = tokenAPI.data.token;
    
          setIsSettingModal(false);
          setOpenRoom(true)
          setChat({
            currentChatRoomId: result.CHANNEL_PSEQ,
            title: result.CHANNEL_NAME,
            members: result.CHAT_USERS,
            token
          });
        }
        return;
      };
    };
    
    const res = await voice.makeChannel(data);

    if(res.data.status == 200){
      let {CHANNEL_PSEQ,CHANNEL_NAME,CHAT_USERS} = res.data.data.channel;

      let reqData = {
        roomid : CHANNEL_PSEQ,
        roomname : CHANNEL_NAME,
        userid : loginUser.userId,
        nickname : loginUser.name,
        usertype : 0,
        langcode : "ko",
        isrequirepwd : false,
        userKey : loginUser.userKey,
        userCd : loginUser.userCd
      };
  
      const tokenAPI = await voice.getToken(reqData);
      if(tokenAPI.status == 200){
        let token = tokenAPI.data.token;
  
        setIsSettingModal(false);
        setOpenRoom(true)
        setChat({
          currentChatRoomId: CHANNEL_PSEQ,
          title: CHANNEL_NAME,
          members: CHAT_USERS,
          token
        });
      };
    };
    setUpdateCheck(!updateCheck);
  };

  return (
    <>
      <div className="blind on"></div>
      <article className="modal-popup popup-chat on">
        <h3 className="pop-title">대화방 개설</h3>

        <div className="plus-list">
          {checkMember.map((user,index)=>(
            <button type="button" key={index} className="btn-line btn-line-gray btn-x" onClick={()=>{removeCheckMember(user)}}>
              <span>{user.userNm}</span>
            </button>
          ))}
        </div>
        <div className="plus-friend-search">
          <form action="" onSubmit={(e) => onSearch(e)}>
            <div className="search-bar">
              <input type="text" placeholder="검색어를 입력하세요." 
                onChange={(e) => setKeyWord(e.target.value)}
                onFocus={onFocus}
                onBlur={onBlur}
              />
              <button type="submit" className={`btn-search ${isFocus && "on"}`}></button>
            </div>
          </form>
        </div>
        <div className="panel-list-wrap">
          <div className="panel-list">
            <h4 className="list-title">즐겨찾기</h4>

            <div className="user-list-wrap">
              {renderList.filter((user)=>
                user.friendSttsCd === "BOOKMARK" && user.friendSttsCd !== "BLOCK"
              ).map((user,index)=>(
                  <ChatCreateModalItem user={user} key={index} setCheckMember={setCheckMember}/>
              ))}
            </div>
          </div>

          <div className="panel-list">
            <h4 className="list-title">친구목록</h4>

            <div className="user-list-wrap">
              {renderList.filter((user)=>user.userType === "FRIEND" && user.friendSttsCd !== "BLOCK").map((user,index)=>(
                <ChatCreateModalItem user={user} key={index} setCheckMember={setCheckMember}/>
              ))}
            </div>
          </div>
        </div>

        <div className="pop-btn-wrap">
          <button 
            type="button" 
            className="btn-line btn-line-gray btn-cancel"
            onClick={()=>{
              setIsCreateModal(false);
            }}
          >
            취소
          </button>
          <button type="button" className="btn-line btn-line-sky" onClick={()=>{createChatRoom(checkMember)}}>개설</button>
        </div>
      </article>
    </>
  );
};


export default ChatCreateModal;