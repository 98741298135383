import React, { useEffect, useRef, useState } from "react";
import ListHeaderComponent from "./ListHeaderComponent";
import ListBodyComponent from "../components/voice/ListBodyComponent";
import ListBottomComponent from "./ListBottomComponent";
import FriendBodyComponent from "./friends/FriendBodyComponent";
import ChatSideComponent from "./chat/ChatSideComponent";
import "../css/ListComponent.css";
import ReplayListComponent from "./voice/ReplayList";
import { useOpenRoomStat } from "../contexts/openRoomContext";
import { useFileSate } from "../contexts/FileContext";
import MiniPlayer from "./MiniPlayer";

const ListComponent = ({ dataList,
                        setDataList,
                        replayList,
                        setReplayList,
                        setProfile, 
                        contentState,
                        setContentState, 
                        setIsCreateModal,
                        setManagementFriendModal,
                        setAddFriendModal,
                        setTotalSearchModal,
                        onCheck,
                        setOnCheck,
                        setProfileModal,
                        refreshing,
                        setRefreshing,
                        historyCheck,
                        setHistoryCheck,
                      }) => {

  const [fileState, setFileState] = useFileSate();
  const [openRoom, setOpenRoom] = useOpenRoomStat();


  const [search,setSearch] = useState([]);

  const onActive = (num) => {
    setOnCheck(num);
    setOpenRoom(false);
    setSearch("");
  };

  //좌측 목록 부분 스크롤 
  const bodyComponent = useRef();
  const [scroll ,setScroll] = useState(0);

  const logScrollPosition = () => {
    if (bodyComponent.current) {
      setScroll(bodyComponent.current.scrollTop);
    }
  };

  //요청한 콜백함수 (목록의 스크롤 위치 및 스크롤 높이 값)
  useEffect(()=>{
    const handleEvent = (e,scrollCb) => {
      let obj = {
        "scrollHeight" : e.target.scrollHeight,
        "clientHeight" : e.target.clientHeight,
        "scrollTop" : e.target.scrollTop,
      };

      scrollCb(JSON.stringify(obj));
    };

    const currentBody = bodyComponent.current;

    window.checkScroll = function(scrollCb){

      if(currentBody){
        currentBody.addEventListener('scroll',(e)=>{handleEvent(e,scrollCb)});
      };
    }

    return () => {
      if (currentBody) {
        currentBody.removeEventListener('scroll', (e)=>{handleEvent(e)});
      }
    };
  },[contentState]);

  // 모바일에서 당겨서 새로고침 함수 
  const handleTouchStart = (e) => {
    if (scroll === 0) {
      const touchStartY = e.touches[0].clientY;
      const handleTouchMove = (moveEvent) => {
        const touchCurrentY = moveEvent.touches[0].clientY;
        const pullDistance = touchCurrentY - touchStartY;

        if (pullDistance > 0) {
          moveEvent.preventDefault(); 

          if (pullDistance > 80) {
            setRefreshing(true);
            setTimeout(() => {
                
              setRefreshing(false);
            }, 500); 
          };
        };
      };

      const handleTouchEnd = () => {
        window.removeEventListener('touchmove', handleTouchMove, { passive: false });
        window.removeEventListener('touchend', handleTouchEnd, { passive: false });
      };

      window.addEventListener('touchmove', handleTouchMove, { passive: false });
      window.addEventListener('touchend', handleTouchEnd, { passive: false });
    }
  };

    
  return (
    <section className="left-panel">
      <ListHeaderComponent 
        listData={dataList} 
        setSearch={setSearch}
        replayList={replayList}
        contentState={contentState} 
        setIsCreateModal={setIsCreateModal} 
        setManagementFriendModal={setManagementFriendModal} 
        setAddFriendModal={setAddFriendModal}
        setTotalSearchModal={setTotalSearchModal}
        onCheck={onCheck}
        setHistoryCheck={setHistoryCheck}
        />
        
      {(fileState.currentFileID && openRoom === false) &&<MiniPlayer contentState={contentState} setContentState={setContentState} setOnCheck={setOnCheck}/>}

      {contentState === "3" &&
        <ul className="lnb">
          <li className={onCheck === "2" ? "on" : ""} onClick={()=>onActive("2")}>방송목록</li>
          <li className={onCheck === "1" ? "on" : ""} onClick={()=>onActive("1")}>다시듣기</li>
        </ul>
      };

      <div className={`panel-list-wrap ${contentState == 3 && "panel-list-wrap2"}`}
        onTouchStart={handleTouchStart}
        ref={bodyComponent}
        onScroll={logScrollPosition}
        >
        {refreshing && (<div></div>)}
        {contentState === "1" && (<FriendBodyComponent 
                                    listData={search.length === 0 ? dataList : search} 
                                    setDataList={setDataList}
                                    setProfile={setProfile}
                                    setManagementFriendModal={setManagementFriendModal} 
                                    setAddFriendModal={setAddFriendModal}
                                    setTotalSearchModal={setTotalSearchModal}
                                    setProfileModal={setProfileModal}
                                    setHistoryCheck={setHistoryCheck}
                                  />)}

        {contentState === "2" && (<ChatSideComponent listData={search.length === 0 ? dataList : search} contentState={contentState}/>)}

        {contentState === "3" && 
          (
            onCheck === "2" ? 
              <ListBodyComponent listData={search.length === 0 ? dataList : search} /> :
              <ReplayListComponent replayList={search.length === 0 ? replayList : search} />
          )
        }
      </div>
      <ListBottomComponent contentState={contentState} setContentState={setContentState} />
    </section>
  );
};

export default ListComponent;
