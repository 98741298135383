import React from 'react';
import '../../css/ListBodyComponent.css';
import ListItem from './ListItem';

const ListBodyComponent = ({listData}) => {
  return (
    <div className="panel-list">
      <h4 className="list-title">방송목록</h4>
      <div className="talk-list-wrap">
        {listData.map((data, index)=>{
          return <ListItem key={index} data={data}/>
        })}
      </div>
    </div>
  );
}

export default ListBodyComponent;
