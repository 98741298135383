import React, { createContext, useContext, useState } from "react";

const FileContext = createContext(null);

export function FileContextProvider({ children }) {
  const [fileSate, setFileSate] = useState({
    currentFileID : null,
    title : null,
    content : null,
    volume : 1,
    prgTime : 0,
    progress : 0,
    thumbnail : null,
    play : false,
    playTime : 0,
    groupId : null,
    type :0
  });

  return (
    <FileContext.Provider value={[fileSate, setFileSate]}>
      {children}
    </FileContext.Provider>
  );
}

//use{something}State useState hoook을 만들어주는 함수입니다.
export function useFileSate() {
  const fileSate = useContext(FileContext);
  if (!fileSate) {
    throw new Error("FileContext is not used");
  }
  return fileSate;
}
