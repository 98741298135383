import React, { useEffect, useState } from 'react';
import { useOpenRoomStat } from '../contexts/openRoomContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFileSate } from '../contexts/FileContext';
import { useLoginUserState } from '../contexts/LoginUser';
import "../css/MiniPlayer.css"

const MiniPlayer = ({contentState,setContentState,setOnCheck}) => {

  const [loginUser, setLoginUser] = useLoginUserState();

  const [fileState,setFileState] = useFileSate();
  const [openRoom,setOpenRoom] = useOpenRoomStat();

  const location = useLocation();
  const [pathName, setPathName] = useState('');

  useEffect(()=>{
    setPathName(location.pathname);
  },[location]);

  const navigate = useNavigate();
  
  useEffect(()=>{
    if(fileState.playTime >= fileState.prgTime){
      setFileState((prev)=>({
        ...prev,
        currentFileID : null,
        title : null,
        content : null,
        volume : 1,
        prgTime : 0,
        progress : 0,
        thumbnail : null,
        playTime : 0,
        groupId : null,
        type:0
      }));
    };
    
  },[fileState.playTime,fileState.type]);

  const closePlayer = (e) => {
    e.stopPropagation();
    setFileState((prev)=>({
      ...prev,
      currentFileID : null,
      title : null,
      content : null,
      volume : 1,
      prgTime : 0,
      progress : 0,
      thumbnail : null,
      playTime : 0,
      groupId : null,
      type:0
    }))
  };

  const pathStyle = pathName === "/voice2" ? { bottom: "0px" } : {};
  
  return (
    <div className="player-wrap" 
      style={pathStyle}
      onClick={() => {

        if(fileState.status != "0"){
          setOnCheck("2");
        }else{
          setOnCheck("1");
        }

        if (contentState === "3") {
        
          if (!openRoom) {
            setOpenRoom(true);
          }
        } else {
          if (fileState.type == 1) {
            setOnCheck("1");
          }else if(fileState.type == 2){
            setOnCheck("2");
          }
          setOpenRoom(true);
          setContentState(3);
          navigate(`/voice?userCd=${loginUser.userCd}&regType=${loginUser.type}&userKey=${loginUser.userKey}`);
        }
    }}>
      <div className="player-now-info">
        <h3>{fileState.title}</h3>
        <p>{fileState.content}</p>
      </div>
      <div className="progress-wrap">
        <span className="progress-now" style={{width: `${fileState.type == "1" ? fileState.progress : fileState.vodFileId != null ? fileState.progress : fileState.status == "1" ? 0 : 100}%`}}></span>
      </div>
      <button type="button" className="btn-close" onClick={closePlayer}>닫기</button>
    </div>
  );
};

export default MiniPlayer;