import React, { useEffect, useState } from 'react';
import { useOpenRoomStat } from '../../../contexts/openRoomContext';
import voice from '../../../api/voice';
import { useLoginUserState } from '../../../contexts/LoginUser';
import env from '../../../env';
import common from '../../common/CommonFunc';

const ManageRequestItem = ({user,setManagementFriendModal,setProfile,reqCheck,setReqCheck,setProfileModal}) => {

  const [loginUser, setLoginUser] = useLoginUserState();
  const [openRoom,setOpenRoom] = useOpenRoomStat();

  //프로필 이미지 url
  const [myProfileIMG,setMyProfileIMG] = useState();

  const openProfile = () => {
    setManagementFriendModal(false)
    setOpenRoom(true);
    setProfileModal(true);
    
    setProfile((prev)=>({
      ...user,
      reqProfile:true
    }))
  };


  //친구요청 처리
  const proFriendReqAPI = async(type) => {
    let reqData = {
      rspnsCd : type,
      userCd : loginUser.userCd,
      reqUserCd : user.userCd,
      userKey : loginUser.userKey
    }

    let proFriendReqResult = await voice.proFriendReq(reqData);
    setReqCheck(!reqCheck);
  };


  useEffect(()=>{
    let url = `${env.url}${user.fileFlctnPath}/${user.fileStrgNm}`;
    let convertURL = url.replace(/\\/g, '/');

    common.imageCheck(
      convertURL,
      () => setMyProfileIMG(`url(${convertURL})`),
      () => setMyProfileIMG(`url('/images/basic-profile.png')`)
    )
  },[user]);

  return (
    <div className="user-list friend-list">
      <a href="#" onClick={(e)=>{e.preventDefault()}}>
        <div className="profile-img">
          <span
              style={{
                background:
                `${myProfileIMG} center center no-repeat`,
              }}
          ></span>
        </div>
        <div className="user-name" onClick={openProfile}>
          <h4>
            {user.userNm}
            {/* <span className="apply-time">10:56 AM</span> */}
          </h4>
          <p>{user.dmndMsg}</p>
        </div>
        <div className="status-btn-wrap">
          <button type="button" className="btn-line btn-line-gray" onClick={()=>proFriendReqAPI("RJCT")}>거절</button>
          <button type="button" className="btn-line btn-line-sky" onClick={()=>proFriendReqAPI("APRB")}>수락</button>
        </div>
      </a>
    </div>
  );
};

export default ManageRequestItem;