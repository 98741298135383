import React, { useEffect, useState } from 'react';
import { useLoginUserState } from '../../../contexts/LoginUser';
import voice from '../../../api/voice';
import { useOpenRoomStat } from '../../../contexts/openRoomContext';
import env from '../../../env';
import common from '../../common/CommonFunc';

const ManageItem = ({user,checkState,setCheckState,setManagementFriendModal,setProfile,setProfileModal}) => {

  const [loginUser, setLoginUser] = useLoginUserState();
  const [openRoom,setOpenRoom] = useOpenRoomStat();

  //프로필 이미지 url
  const [myProfileIMG,setMyProfileIMG] = useState();

  const openProfile = () => {
    setManagementFriendModal(false)
    setOpenRoom(true);
    setProfileModal(true);
    setProfile((prev)=>({
      ...user
    }))
  };


  useEffect(()=>{
    let url = `${env.url}${user.fileFlctnPath}/${user.fileStrgNm}`;
    let convertURL = url.replace(/\\/g, '/');

    common.imageCheck(
      convertURL,
      () => setMyProfileIMG(`url(${convertURL})`),
      () => setMyProfileIMG(`url('/images/basic-profile.png')`)
    )
  },[user]);

  //친구 차단 및 차단 해제
  const friendBlock = async(friendCd,type) =>{
    let userData = {
      userCd : loginUser.userCd,
      friendCd : friendCd,
      friendSttsCd : type,
      userKey : loginUser.userKey
    };

    try {
      let result = await voice.uptFriendStts(userData);
      if(result.upCnt > 0){
        setCheckState(!checkState);
      };
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div className="user-list">
      <a href="#" onClick={(e)=>{e.preventDefault()}}>
        <div className="profile-img">
          <span
              style={{
                background:
                `${myProfileIMG} center center no-repeat`,
              }}
            ></span>
        </div>
        <div className="user-name" onClick={openProfile}>
          <h4>{user.userNm}</h4>
          <h4 style={{
            fontSize:"14px",
            color:"gray"
          }}>{user.formatCd}</h4>
        </div>
        {user.friendSttsCd === "BLOCK" ?
          <button type="button" className="btn-line btn-line-gray" onClick={()=>friendBlock(user.userCd,"NORMAL")}>해제</button> :
          <button type="button" className="btn-line btn-line-orange" onClick={()=>friendBlock(user.userCd,"BLOCK")}>차단</button> 
        }
      </a>
    </div>
  );
};

export default ManageItem;