import React, { useEffect, useState } from 'react';
import voice from '../../api/voice';
import { useFileSate } from '../../contexts/FileContext';
import env from '../../env';
import { useLoadingState } from '../../contexts/LoadingContext';

const ReplayVodItem = ({vod,vodList}) => {

  const [fileState, setFileState] = useFileSate();

  const [fileId, setFileId] = useState('');
  const [fileTitle, setFileTitle] = useState('');
  const [playTime, setPlayTime] = useState('');

  const [ loadingState, setLoadingState] = useLoadingState();

  const [downLink ,setDownLink] = useState('');

  useEffect(()=>{
    if(vod){      
      setFileId(vod.children.filter((el) => el.name === "fileId")[0].value)
      setFileTitle(vod.children.filter((el) => el.name === "fileTitle")[0].value)
      setPlayTime(vod.children.filter((el) => el.name === "playTime")[0].value)
    };
     setDownLink(`${env.mediaURL}rest/stream/${vod.children.filter((el) => el.name === "fileId")[0].value}/convert`) //테스트 
  },[vodList]);

  const currentFileSet = async() => {    
    setLoadingState(true);
    setFileState((prev)=>({
      ...prev,
      play:false
    }));
    const response = await voice.getFile(fileId);

    if(response.status == 'S'){
      setFileState((prev)=>({
        ...prev,
        title: fileTitle,
        content: '',
        prgTime : playTime,
        thumbnail : '',
        currentFileID : response.url,
        type: '1',
        vodFileId : fileId,
        play:false
      }));
      setLoadingState(false);
    };
  }

  return (
    <li>
      <a href="#" className="play-title" onClick={(e)=>{e.preventDefault(); currentFileSet()}}  style={{width:"60%"}}><span>{fileTitle}</span></a>
      <a href={downLink} className='play-download' ><span>다운로드</span></a>
    </li>
  );
};

export default ReplayVodItem;