import { useEffect, useState } from "react";
import voice from "../../api/voice";
import { useChatState } from "../../contexts/ChatContext";
import { useLoginUserState } from "../../contexts/LoginUser";
import { useOpenRoomStat } from "../../contexts/openRoomContext";
import common from "../common/CommonFunc";
import env from "../../env";

const ChatListItem = ({item,contentState}) => {
  const [openRoom, setOpenRoom] = useOpenRoomStat();
  const [chat, setChat] = useChatState();

  const [loginUser, setLoginUser] = useLoginUserState();
  const [notYetChat, setNotYetChat] = useState(0);

  const [profileIMG,setProfileIMG] = useState();



  const notYetChatCnt = async() => {
    let reqData = {
      "keyword": null,
      "user_id": loginUser.userId,
      "channel_pseq": item.CHANNEL_PSEQ,
      userKey : loginUser.userKey,
      userCd : loginUser.userCd
    };

    if(loginUser.userId){
      const res = await voice.channelNotyetCnt(reqData);
      setNotYetChat(res.data.cnt);
    }
  }
  
  useEffect(()=>{
    notYetChatCnt();

    const intervalId = setInterval(() => {
      notYetChatCnt();
    }, 10000);

    return () => {
      clearInterval(intervalId); 
    };
  },[item.CHANNEL_PSEQ,contentState])


  const onClick = async() => {
    
    let reqData = {
      roomid : item.CHANNEL_PSEQ,
      roomname : item.CHANNEL_NAME,
      userid : loginUser.userId,
      nickname : loginUser.name,
      usertype : 0,
      langcode : "ko",
      isrequirepwd : false,
      userKey : loginUser.userKey,
      userCd : loginUser.userCd
    }

    const tokenAPI = await voice.getToken(reqData);
    if(tokenAPI.status == 200){
      let token = tokenAPI.data.token;

      setOpenRoom(true)
      setChat({
        currentChatRoomId: item.CHANNEL_PSEQ,
        title: item.CHANNEL_NAME,
        members: item.CHAT_USERS,
        CHAT_USER_CNT : item.CHAT_USER_CNT,
        token
      });
    }
  };

  useEffect(()=>{
    let url = `${env.url}${item.CHANNEL_IMG_PATH}`;
    let convertURL = url.replace(/\\/g, '/');

    common.imageCheck(
      convertURL,
      () => setProfileIMG(`url(${convertURL})`),
      () => setProfileIMG(`url('/images/basic-profile.png')`)
    )
  },[item]);

  
  let totalDate;

  if (item.LOG_DT) {
    let date = new Date(item.LOG_DT);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let hours = date.getHours();
    let minutes = date.getMinutes();

    totalDate = `${year}년 ${month}월 ${day}일 ${hours}시 ${minutes}분`;
  }


  return (
    <div
      className={`talk-list ${
        chat.currentChatRoomId === item.CHANNEL_PSEQ && "act"
      }`}
      onClick={onClick}
    >
      <a>
        <div className="profile-img">
          <span
            style={{
              background: `${profileIMG} center center no-repeat`
            }}
          ></span>
        </div>
        <div className="talk-thumb">
          <h4>
            {item.CHANNEL_NAME}
            {notYetChat > 0 && <i className="new-talk-num">{notYetChat}</i>}
          </h4>
            <span className="talk-date" style={{
              fontSize : "12px"
            }}>{totalDate}</span>
        </div>
      </a>
    </div>
  );
};

export default ChatListItem;
