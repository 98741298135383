import React, { createContext, useContext, useState } from "react";

const FriendListContext = createContext(null);

export function FriendListContextProvider({ children }) {
  const [friendState, setFriendState] = useState([]);

  return (
    <FriendListContext.Provider value={[friendState, setFriendState]}>
      {children}
    </FriendListContext.Provider>
  );
}

//use{something}State useState hoook을 만들어주는 함수입니다.
export function useFriendState() {
  const friendState = useContext(FriendListContext);
  if (!friendState) {
    throw new Error("FriendListContext is not used");
  }
  return friendState;
}
