import React, { useEffect, useState } from 'react';
import ContentComponent from '../ContentComponent';
import ListComponent from '../ListComponent';
import voice from "../../api/voice";
import useUserCdEffect from '../common/useUserCdPath';

function VoiceComponent({audio,contentState,setContentState,onCheck,setOnCheck}) {

  const [dataList, setDataList] = useState([]); //방송목록

  const [replayList, setReplayList] = useState([]); //VOD (다시보기) 목록

  const [refreshing, setRefreshing] = useState(false);

  //url 에서 userCd가져오기 = 사용자 정보 가져오기
  useUserCdEffect();
  
  useEffect(()=>{
    setContentState("3");
  },[])

  useEffect(()=>{
    if (onCheck === "2") {
      getList();
    };
    
    if (onCheck === "1") {
      playListAPI();
    };

  },[onCheck,refreshing]);

  const getList = async () => {

    try {
      let voiceList = await voice.getChannelList();
      let programList = await voice.getProgramList();

      // 채널리스트 및 프로그램 리스트 정보 종합
    const resultList = voiceList.map((data) => {
      let resultData = {
        chId: "",
        title: "",
        content: "",
        fileID: "",
        prgTime: "",
        thumbnail: "",
        status:"",
      };

      data.forEach((ch) => {
        if (ch.name === "chId") {
          resultData.chId = ch.value;
        }

        if (ch.name === "chName") {
          resultData.title = ch.value;
        }

        if (ch.name === "chDesc") {
          resultData.content = ch.value;
        }

        if (ch.name === "httpUrl") {
          resultData.fileID = ch.value;
        }

        if (ch.name === "status"){
          resultData.status = ch.value;
        }
      });

      if (programList.length > 0) {
        programList.forEach((program) => {
          const programData = program.find(
            (obj) => obj.name === "chName" && obj.value === resultData.title
          );

          if (programData) {
            const prgTime = program.find(
              (obj) => obj.name === "prgTime"
            )?.value;
            const thumbnail = program.find(
              (obj) => obj.name === "thumbnail"
            )?.value;
            resultData.prgTime = prgTime;
            resultData.thumbnail = thumbnail;
          }
        });
      }

      return resultData;
    });
    setDataList(resultList);
    } catch (error) {
      
    }
  };

  const playListAPI =  async() => {
    const playListResult = await voice.playList();
    setReplayList(playListResult);
  };

  return (
    <>
      <ListComponent 
        playListAPI={playListAPI}
        dataList={dataList} 
        setDataList={setDataList} 
        replayList={replayList}
        setReplayList={setReplayList}
        contentState={contentState} 
        setContentState={setContentState} 
        onCheck={onCheck} 
        setOnCheck={setOnCheck}
        refreshing={refreshing}
        setRefreshing={setRefreshing}
      />
      <ContentComponent contentState={contentState} setContentState={setContentState} audio={audio} onCheck={onCheck} setOnCheck={setOnCheck}/>
    </>
  );
}

export default VoiceComponent;