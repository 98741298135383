import React, { createContext, useContext, useState } from "react";

const OpenRoomContext = createContext(null);

// {something}ContextProvider 만들어주는 함수입니다.
export function OpenRoomContextProvider({ children }) {
  const [openRoomStat, setOpenRoomStat] = useState(/* initial value */);

  return (
    <OpenRoomContext.Provider value={[openRoomStat, setOpenRoomStat]}>
      {children}
    </OpenRoomContext.Provider>
  );
}

// use{something}State useState hook을 만들어주는 함수입니다.
export function useOpenRoomStat() {
  const openRoomStat = useContext(OpenRoomContext);

  if (!openRoomStat) {
    throw new Error("OpenRoomContext is not used");
  }

  return openRoomStat;
}
