import React, { createContext, useContext, useState } from "react";

const ChatContext = createContext(null);

export function ChatContextProvider({ children }) {
  const [chatSate, setChatSate] = useState({
    currentChatRoomId: null,
    title: null,
    members: null,
    token : null
  });

  return (
    <ChatContext.Provider value={[chatSate, setChatSate]}>
      {children}
    </ChatContext.Provider>
  );
}

//use{something}State useState hoook을 만들어주는 함수입니다.
export function useChatState() {
  const chatState = useContext(ChatContext);
  if (!chatState) {
    throw new Error("FileContext is not used");
  }
  return chatState;
}
