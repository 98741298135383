const common = {
  imageCheck : (url, onSuccess, onError) =>{
    const img = new Image();
    img.src = url

    img.onload = onSuccess;
    img.onerror = onError;
  },
  converMs : (time) => {
      let timeParts = time.split(':');

      let hours = parseInt(timeParts[0], 10);
      let minutes = parseInt(timeParts[1], 10);
      let seconds = parseInt(timeParts[2], 10);

      let milliseconds = (hours * 60 * 60 * 1000) + (minutes * 60 * 1000) + (seconds * 1000);

      return milliseconds;
  },
};


export default common;