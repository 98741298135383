import React, { useEffect, useState } from 'react';
import { useLoginUserState } from '../../../contexts/LoginUser';
import voice from '../../../api/voice';
import { useOpenRoomStat } from '../../../contexts/openRoomContext';
import env from '../../../env';
import common from '../../common/CommonFunc';

const AddStateItem = ({
                    user,
                    setAddRequest,
                    setAddFriendState,
                    checkState,
                    setCheckState,
                    setAddFriendModal,
                    setProfile,
                    setProfileModal}) => {

  const [loginUser, setLoginUser] = useLoginUserState();
  const [openRoom,setOpenRoom] = useOpenRoomStat();

  //프로필 이미지 url
  const [myProfileIMG,setMyProfileIMG] = useState();

  const openProfile = () => {
    setAddFriendModal(false)
    setOpenRoom(true);
    setProfileModal(true);
    setProfile((prev)=>({
      ...user
    }))
  }

  const requestOpen = () =>{
    setAddRequest(true);

    setAddFriendState((prev)=>({
      userNm : user.userNm,
      userMsg : user.userMsg,
      userCd: loginUser.userCd,
      toUserCd : user.userCd,
      filePath : myProfileIMG,
      userId : user.userId,
      formatCd : user.formatCd
    }));
  };

  //신청 취소
  const requestCancle = async(friendCd) =>{
 
    let reqData = {
      userCd : loginUser.userCd,
      toUserCd : friendCd,
      userKey : loginUser.userKey
    }
    try {

      let cancelFriendReqResult = await voice.cancelFriendReq(reqData);
      if(cancelFriendReqResult.result === "S"){
        setCheckState(!checkState);
      }
    } catch (error) {
      console.error(error);
    };
  };

  useEffect(()=>{
    let url = `${env.url}${user.fileFlctnPath}/${user.fileStrgNm}`;
    let convertURL = url.replace(/\\/g, '/');

    common.imageCheck(
      convertURL,
      () => setMyProfileIMG(`url(${convertURL})`),
      () => setMyProfileIMG(`url('/images/basic-profile.png')`)
    )
  },[user])

  return (
    <div className="user-list friend-list">
      <a href="#" onClick={(e)=>{e.preventDefault()}}>
        <div className="profile-img">
          <span
            style={{
              background: `${myProfileIMG} center center no-repeat`,
            }}
          ></span>
        </div>
        <div className="user-name" onClick={openProfile}>
          <h4>{user.userNm}</h4>
          <p style={{
            fontSize:"12px",
            color:"gray"
          }}>{user.formatCd}</p>
        </div>
        <div className="status">
          <p>10:56 AM</p>

          {user.friendSttsCd === "N" && user.dmndReq === "N" && user.dmndRes === "N"&& <button type="button" className="btn-line btn-line-sky" onClick={requestOpen}>신청</button>}
          {user.friendSttsCd === "N" && user.dmndReq === "REQ" && <button type="button" className="btn-line btn-line-sky" onClick={()=>requestCancle(user.userCd)}>취소</button>}
        </div>
      </a>
    </div>
  );
};

export default AddStateItem;