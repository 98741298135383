import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useFileSate } from '../contexts/FileContext';
import '../css/PlayerComponent.css'
import voice from '../api/voice';
import common from './common/CommonFunc';

const PlayerComponent = ({audio}) => {

  const [fileState, setFileState] = useFileSate();

  const [currentTime, setCurrentTime] = useState();

  const [temp,setTemp] = useState(''); //실시간 방송정지시 currentFileID 백업용도 정지시 null를 하기 때문에

  useEffect(()=>{
    if(fileState.prgTime == currentTime){
      setFileState((prev)=>({
        ...prev,
        play:false
      }));
    };
  },[currentTime])


  useEffect(() => {
    const updateProgressBar = () => {
      if(fileState.type == "1"){
        let percentage = (fileState.playTime / fileState.prgTime) * 100;
        if (percentage > 100) percentage = 100;
        setFileState((prev) => ({
          ...prev,
          progress: percentage,
        }));
      } 
    };
    updateProgressBar();
  }, [fileState.playTime]);

  //음성방송 컨텐츠부분에서 사용하는 함수
  window.reqAudioPlayPage=async function() {

    setFileState((prev)=>({
      ...prev,
      currentFileID : !temp ?  fileState.currentFileID : temp
    }))
    audioPlay(fileState.type);
  }

  window.isPlay = async function(playCb){

    let date = new Date();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let current = `${hours}:${minutes}:${seconds}`;

    let startTime = fileState.startTime; //실시간 방송의 시작 시간
    let currentCnv = common.converMs(current);

    let currentStartTime;
    if(startTime <= currentCnv){
      currentStartTime = (currentCnv - startTime) / 1000;
    };

    //type 1 : vod  , 2 : 실시간
    var obj = {
    "title": fileState.title,
    "type": fileState.play == false ? 0 : fileState.type,
    "prgTime": parseInt(fileState.prgTime),
    "currentTime": fileState.type == "1" ? parseInt(fileState.playTime) : parseInt(currentStartTime),
    };
  
    // 웹워커 사용
    if (window.Worker) {
      let worker = new Worker("/js/worker.js");

      worker.onerror = (e) => {
        console.error("error " + e.message);
      };

      worker.onmessage = (e) => {
        let data = e.data;

        obj.title = data.title;
        obj.prgTime = data.prgTime;
        obj.currentTime = data.currentTime;
        obj.type = data.type;

        if(obj.type == 0){
          setFileState((prev)=>({
            ...prev,
            currentFileID : null,
            title : null,
            content : null,
            volume : 1,
            prgTime : 0,
            progress : 0,
            thumbnail : null,
            playTime : 0,
            // groupId : null,
            type:0
          }));
        }


        let userAgent = navigator.userAgent.toUpperCase();

        let isMobile = userAgent.match(/ANDROID|WEBOS|IPHONE|IPAD|IPOD|BLACKBERRY|IEMOBILE|OPERA MINI/i);
        let isAndroid = userAgent.indexOf("ANDROID") !== -1;
        let isiPhone = userAgent.indexOf("IPHONE") !== -1;
        let isiPad = userAgent.indexOf("IPAD") !== -1;
        let isiPad2 = userAgent.indexOf("MACINTOSH; INTEL MAC OS") !== -1;


        if(isiPhone || isiPad || isiPad2){
          window.webkit.messageHandlers.isPlay.postMessage(obj);
          // playCb(JSON.stringify(obj));
        }else{
          // playCb(JSON.stringify(obj));  //로컬에서 테스트할때
          window.callback.isPlay(JSON.stringify(obj));  //배포에서
        }

      };

      function stop() {
        worker.postMessage("stop");
        worker.terminate();
      };

      window.stopIsPlayCallback = stop;

      worker.postMessage(obj);
    };
  };
  
   //전역으로 호출하기 위함
  window.reqAudioStop= function() {
    audioStop(fileState);
  };

  //전역으로 호출하기 위함
  window.reqAudioPlay=async function(contentType,fileId,cb) {

    let prgTime = null;
    let title = null;
    let type = 0;

    setFileState((prev)=>({
      ...prev,
      play:false
    }));

    if(contentType == 'live'){
      let res = await voice.getChannelView(fileId);
      let schedulCheck = await voice.scheduleCheck(fileId);

      let startTime;
      
      title = res.title;
      prgTime = parseInt(schedulCheck.duration); //총 재생시간 되는 초
      startTime = parseInt(schedulCheck.startTime); //방송 시작 초
      type = '2';
  
      setFileState((prev)=>({
        ...prev,
        content : res.content,
        title : res.title,
        currentFileID : res.currentFileID,
        prgTime,
        type,
        startTime
      }));
    };

    if(contentType == 'vod'){

      const response = await voice.getFile(fileId);

      if(response.status == 'S'){

        
        const viewResult = await voice.vodView(fileId);  

        viewResult.filter((vod)=>{
          if(vod.name == "playTime"){
            prgTime = vod.value;
          };
  
          if(vod.name == "realFileName"){
            title = vod.value;
          };
  
        });
  
        type = '1';
        setFileState((prev)=>({
          ...prev,
          title,
          prgTime,
          type,
          playTime:0,
          currentFileID : `${response.url}`
        }));
      };
    };

    audioPlay();

    //오디오가 재생될때 시작된다는 상태값을 전달
    // window.callback.isStart(true);
  };


  //볼륨 상태체크
  window.checkVolume=function(){
    return parseInt((fileState.volume * 10).toFixed(1));
  };

  //볼륨 제어
  window.volumeControl=function(num){
    if(num > 10) num = 10;
    if(num < 0 ) num = 0;

    let result = parseFloat((num * 0.1).toFixed(1));
    setFileState((prev)=> ({
      ...prev,
      play:true,
      volume : result
    }));
  };

  const audioPlay = (type) => {
    let userAgent = navigator.userAgent.toUpperCase();

    let isMobile = userAgent.match(/ANDROID|WEBOS|IPHONE|IPAD|IPOD|BLACKBERRY|IEMOBILE|OPERA MINI/i);
    let isAndroid = userAgent.indexOf("ANDROID") !== -1;
    let isiPhone = userAgent.indexOf("IPHONE") !== -1;
    let isiPad = userAgent.indexOf("IPAD") !== -1;
    let isiPad2 = userAgent.indexOf("MACINTOSH; INTEL MAC OS") !== -1;

    const handlePlayState = (volume, type) => {
      setFileState((prev) => ({
        ...prev,
        play: true,
        volume: volume,
        ...(type && { type })
      }));
    };
    
    if (isiPhone || isiPad || isiPad2) {
      handlePlayState(0, type);
      setTimeout(() => {
        //ios 콜백 전달 동시에하면 못받아서 1초 딜레이
        window.webkit.messageHandlers.isStart.postMessage(true);
      }, 1000);
    } else {
      handlePlayState(1, type);
      window.callback.isStart(true);
    };
  };

  const audioStop = (data) => {
    // setTemp({...fileState});
    setTemp(fileState.currentFileID)

    setFileState((prev)=>({
      ...prev,
      play:false,
      currentFileID: data.type == "2" ? null : data.currentFileID,
      type: data ? data.type : 0
      // volume:0
    }));

    window.stopIsPlayCallback();
  };


  //영상 속성 설정
  const config = {
    file: {
      forceAudio: true,
    }
  };

  return (
    <div style={{height:0}}>
      {fileState.currentFileID != null && 
        <ReactPlayer 
          playsinline
          className = "player_style"
          controls
          ref={audio}
          url={`${fileState.currentFileID}`}
          config={config}
          playing={fileState.play}
          onProgress={(e) => {
            // console.log(fileState)
            if(fileState.play){
              setFileState((prev)=>({
                ...prev,
                playTime : e.playedSeconds,
              }))
            }
          }}
          volume={parseFloat(fileState.volume)}
          onError={(error)=>{
            console.error(error)
            console.error("방송재생이 불가능합니다.")
            setFileState((prev)=>({
              ...prev,
              volume : 1,
              prgTime : 0,
              progress : 0,
              thumbnail : null,
              playTime : 0,
              // groupId : null,
              type:0
            }));
          }}
        />
      }
    </div>
  );
};

export default PlayerComponent;