import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoginUserState } from "../../contexts/LoginUser";
import voice from "../../api/voice";

const useUserCdPath = () => {
  const [loginUser, setLoginUser] = useLoginUserState();

  const location = useLocation();

  const params = new URLSearchParams(location.search);
  
  const resultUserCd = params.get('userCd');
  const resultRegType = params.get('regType');
  const resultUserKey = params.get('userKey');
  
  const navigate = useNavigate();

  useEffect(() => {

    const login = async () =>{
      let reqData = {
        userCd : resultUserCd,
        userKey : resultUserKey
      };

      let loginResult = await voice.requestLogin(reqData);
      if(loginResult.result == "S"){
        let { userNm, userMsg, filePath, userId } = loginResult.user;

        setLoginUser(()=>({
          userCd : resultUserCd,
          userId: userId,
          userKey: resultUserKey,
          name:userNm,
          stateMSG:userMsg,
          thumbnail:filePath,
          type: resultRegType
        }));
      }else{
        // navigate("/notFound");
      }
    };    

    if(resultUserKey && resultUserKey != "undefined" && resultUserKey != "null"){
      login();
    }else{
      // navigate("/notFound");
    };
  }, [resultUserCd]);
};

export default useUserCdPath;