import React, {  useEffect, useRef, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PlayerComponent from '../PlayerComponent';
import SkipNavComponent from '../SkipNavComponent';
import FriendComponent from '../page/FriendComponent';
import ChatComponent from '../page/ChatComponent';
import VoiceComponent from '../page/VoiceComponent';
import "../../css/responsive.css"; //컴포넌트 import 뒤에 있어야 반응형 적용
import { useOpenRoomStat } from '../../contexts/openRoomContext';
import NotFound from './NotFound';
import voice from '../../api/voice';

const LoginComponent = () => {

  const [openRoom,setOpenRoom] = useOpenRoomStat();

  const [contentState, setContentState] = useState("1");

  const [onCheck, setOnCheck] = useState("2"); // 음성방송 부분에서 방송목록/다시듣기 구분

  const audio = useRef(null);

  const [historyCheck,setHistoryCheck] = useState(false); //친구 탭 - 통합검색,관리,추가 모달에서 뒤로가기 활성화를 위한 state


  //모바일 기기에서 뒤로가기 기능이 필요하여서 추가
  // openRoom(컨텐츠 오픈 여부), historyCheck(친구관련 모달창들)
  useEffect(() => {
    const popstateHandler = () => {
      if (openRoom && window.history.length > 1) {
        window.history.go(1);
        setOpenRoom(false);
      }

      if (historyCheck && window.history.length > 1) {
        window.history.go(1);
        setHistoryCheck(false);
      }
    };
  
    if (openRoom || historyCheck) {
      window.history.pushState(null, null, window.location.href);
      window.addEventListener("popstate", popstateHandler);
    }

    return () => {
      window.removeEventListener("popstate", popstateHandler);
    };
  }, [openRoom,historyCheck]);

  window.refreshKey = async function(userCd){
    try {
        const res = await voice.userKeyRefresh(userCd);
        return res;
    } catch (error) {
        console.error(error);
    }
  };


  //모바일에서 키보드 나타났을때에 대한 css처리
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 560) {
        if(window.innerHeight <= 500){
          document.querySelector('.gnb-wrap').style.display = 'none';
          document.querySelector('.panel-list-wrap').style.maxHeight = '100%';
        } else {
          document.querySelector('.gnb-wrap').style.display = 'block';
          document.querySelector('.panel-list-wrap').style.maxHeight = 'calc(100% - 250px)';
        };
      };
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <BrowserRouter basename='/navi'>
      <PlayerComponent audio={audio} />
      <SkipNavComponent />
      <div className="site-wrap" id="#contents">
        <Routes>
          <Route path="/main" element={<FriendComponent  contentState={contentState} setContentState={setContentState} historyCheck={historyCheck} setHistoryCheck={setHistoryCheck} onCheck={onCheck} setOnCheck={setOnCheck}/>}/>
          <Route path="/friend" element={<FriendComponent  contentState={contentState} setContentState={setContentState} historyCheck={historyCheck} setHistoryCheck={setHistoryCheck} onCheck={onCheck} setOnCheck={setOnCheck}/>}/>
          <Route path="/chat" element={<ChatComponent  contentState={contentState} setContentState={setContentState} onCheck={onCheck} setOnCheck={setOnCheck}/>}/>
          <Route path="/voice" element={<VoiceComponent audio={audio} contentState={contentState} setContentState={setContentState} onCheck={onCheck} setOnCheck={setOnCheck}/>}/>

          <Route path="/main2" element={<FriendComponent  contentState={contentState} setContentState={setContentState} historyCheck={historyCheck} setHistoryCheck={setHistoryCheck} onCheck={onCheck} setOnCheck={setOnCheck}/>}/>
          <Route path="/friend2" element={<FriendComponent  contentState={contentState} setContentState={setContentState} historyCheck={historyCheck} setHistoryCheck={setHistoryCheck} onCheck={onCheck} setOnCheck={setOnCheck}/>}/>
          <Route path="/chat2" element={<ChatComponent  contentState={contentState} setContentState={setContentState} onCheck={onCheck} setOnCheck={setOnCheck}/>}/>
          <Route path="/voice2" element={<VoiceComponent audio={audio} contentState={contentState} setContentState={setContentState} onCheck={onCheck} setOnCheck={setOnCheck}/>}/>

          <Route path="/notFound" element={<NotFound />}/>
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default LoginComponent;