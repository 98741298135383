import React, { useEffect, useRef, useState } from 'react';
import voice from '../../api/voice';
import env from '../../env';
import { useFriendState } from '../../contexts/FriendListContext';
import common from '../common/CommonFunc';
import { useLoginUserState } from '../../contexts/LoginUser';

const MyProfile = ({profile,setProfile,setProfileModal}) => {

  const [loginUser, setLoginUser] = useLoginUserState();

  const [friendState, setFriendState] = useFriendState();

  const inputValue = useRef();
  const maxLength = 60;

  const [textCnt, setTextCnt] = useState(0);
  //상태메세지 수정 상태 여부
  const [editStateMSG , setEditStateMSG] = useState(false);
  const [stateMSG, setStateMSG] = useState(profile.userMsg);

  const [changeFile, setChangeFile] = useState({});

  //프로필 이미지 url
  const [myProfileIMG,setMyProfileIMG] = useState();

  //인풋창 value 가져오기
  const handleChange = (event) => {
    let currentMSG = event.target.value;

    if(currentMSG.length > 60){
      return;
    }
    setTextCnt(currentMSG.length);
  };

  //상태메세지 엔터 부분
  const changeStateMSG = () =>{
    setEditStateMSG(false);
  }

  //프로필 저장 요청 부분
  const saveProfile = async() =>{
    let currentMSG = inputValue.current.value;

    let reqData = {
      userMsg : currentMSG,
      userCd : profile.userCd,
      userKey : loginUser.userKey
    }

    //상태메세지 저장
    const uptProfileMsgResult = await voice.uptProfileMsg(reqData);
    if(uptProfileMsgResult.result === "S"){
      setStateMSG(currentMSG)
      setProfile((prev)=>({
        ...prev,
        userMsg : currentMSG
      }));
    };

    let reqData2 = {
      file : changeFile,
      userCd : profile.userCd,
      userKey : loginUser.userKey
    }
    //프로필 이미지 
    const uptProfileIMGResult = await voice.profileUpload(reqData2);
    if(uptProfileIMGResult.result === "S"){

      let regData = {
        userCd : profile?.userCd,
        regType : loginUser.userRegType,
        userKey : loginUser.userKey
      };

      const updatedDataList = await voice.getFriendList(regData);
      if (updatedDataList.result === "S") {

        setFriendState(updatedDataList.userList)
      };
    }
  }

  useEffect(()=>{
    let url = `${env.url}${profile.fileFlctnPath}/${profile.fileStrgNm}`;
    let convertURL = url.replace(/\\/g, '/');

    common.imageCheck(
      convertURL,
      () => setMyProfileIMG(`url(${convertURL})`),
      () => setMyProfileIMG(`url('/images/basic-profile.png')`)
    )

  },[])


  const inputRef = useRef(null);

  const openUpload = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);

    setMyProfileIMG(`url(${imageUrl})`)
    setChangeFile(file);
  };



  return (
    <article className="profile">
      <div
        className="myprofile-img"
        style={{
          background: myProfileIMG,
        }}
      >
        <input
          type="file"
          id="fileInput"
          accept="image/*"
          style={{ display: 'none' }}
          ref={inputRef}
          onChange={handleFileChange}
        />
        <button 
          type="button"
          className="btn-line-icon2 btn-edit"
          onClick={openUpload}
        >
          사진변경
        </button>
      </div>

      <div className="profile-cont">
        <div className="cont-top">
          <h2 className="myname">{profile.userNm}</h2>
          <div className="myment-wrap">
            <input
              ref={inputValue}
              type="text"
              disabled = {!editStateMSG}
              className={`myment ${editStateMSG ? 'edit' : ''}`}
              defaultValue={stateMSG}
              onChange={handleChange}
              maxLength={maxLength}
            />
            <button type="button" className="btn-line-icon2 btn-enter"
              style={{
                display : `${editStateMSG ? "inline-block" : "none"}`
              }}
              onClick={changeStateMSG}
            >
              입력
            </button>
          </div>
          <p className="txt-count"
            style={{
              display : `${editStateMSG ? "inline-block" : "none"}`
            }}
          >
            <span className="counting">{textCnt}</span>/60
          </p>
          <button
            type="button"
            className="btn-line-icon2 btn-edit btn-edit-message"
            style={{
              display: `${!editStateMSG ? "" : "none"}`
            }}
            onClick={()=>{
              setTextCnt(inputValue.current.value.length);
              setEditStateMSG(true);
            }}
          >
            메시지 변경
          </button>
        </div>
        <div className="cont-btn-wrap">
          <button type="button" className="btn-line btn-line-gray btn-cancel"
            onClick={()=>{
              setProfileModal(false);
            }}
          >
            취소
          </button>
          <button type="button" className="btn-line btn-line-sky"
            onClick={()=>{
              setEditStateMSG(false);
              saveProfile();
            }}
          >
            저장
          </button>
        </div>
      </div>
    </article>
  );
};

export default MyProfile;