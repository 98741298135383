import ChatListItem from "./ChatListItem";

const ChatSideComponent = ({ listData,contentState }) => {

  //채팅방 정렬
  function compareDate(a, b) {
    return new Date(b.LOG_DT) - new Date(a.LOG_DT);
  }

  listData?.sort(compareDate);

  return (
    <div className="panel-list">
      <div className="list-title"></div>

      <div className="talk-list-wrap">
        {listData?.map((item, i) => (
          <ChatListItem
            key={i}
            item={item}
            contentState={contentState}
          />
        ))}
      </div>
    </div>
  );
};

export default ChatSideComponent;
