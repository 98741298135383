import React, { useEffect, useState } from "react";
import "../css/ContentComponent.css";
import ContentRoomComponent from "./voice/ContentRoomComponent";
import { useOpenRoomStat } from "../contexts/openRoomContext";
import ChatRoomComponent from "./chat/ChatRoomComponent";
import { useChatState } from "../contexts/ChatContext";
import FriendProfile from "./friends/FriendProfile";
import AddFriendModal from "./friends/AddFriendModal";
import ManagementModal from "./friends/ManagementModal";
import AddRequest from "./friends/AddModal/AddRequest";
import TotalSearchModal from "./friends/TotalSearchModal";
import ReplayContentRoomComponent from "./voice/ReplayContentRoomComponent";
import { useFileSate } from "../contexts/FileContext";
import { useLoadingState } from "../contexts/LoadingContext";

const ContentComponent = ({ contentState,setContentState,audio,
                            addFriendModal,
                            managementFriendModal,
                            setManagementFriendModal,
                            setAddFriendModal,
                            totalSearchModal,
                            setTotalSearchModal,
                            setIsCreateModal,
                            profile,
                            setProfile,
                            onCheck,
                            profileModal,
                            setProfileModal,
                            historyCheck,
                            updateCheck,
                            setUpdateCheck
                           }) => {
  const [openRoom,setOpenRoom] = useOpenRoomStat();
  const [fileState, setFileState] = useFileSate();
  const [chat, setChat] = useChatState();

  //AddFriendModal 에서 친구 요청시 나오는 모달 제어 state 
  const [addRequest,setAddRequest] = useState(false);

  //친구 신청 정보 담은  state
  const [addFriendState , setAddFriendState] = useState({
    userNm : null,
    userMsg : null,
    // fromUserCd: null,
    userCd: null,
    toUserCd : null,
    dmndMsg : null,
    filePath : null,
    userId : null,
  });

  //친구 추가, 취소 동적으로 처리하기 위해 상태값 
  const [checkState, setCheckState] = useState(false);

  const [viewCheck, setViewCheck] = useState(false);

  //프로필에서 친구 추가 신청을 하였는지
  const [profileAddCheck,setProfileAddCheck] = useState(false);

  const [loadingState ,setLoadingState] = useLoadingState();

  useEffect(()=>{
    setProfileAddCheck(false);
  },[profile])

  switch (contentState) {
    case "1":
      return (
        <article className={`contents-wrap ${addFriendModal || totalSearchModal ? "" : managementFriendModal ?  "friend-manage-wrap":"contents-wrap2" } 
                            friend ${totalSearchModal && historyCheck ? "search-all on" : addFriendModal && historyCheck ? "on" : managementFriendModal && historyCheck ? "on" :  profileModal && historyCheck ? "on" : ""}`}>
          {totalSearchModal && historyCheck? (
            <TotalSearchModal 
              setTotalSearchModal={setTotalSearchModal} 
              setContentState={setContentState} 
              profile={profile} 
              setProfile={setProfile}
              profileModal={profileModal}
              setProfileModal={setProfileModal}
             />
          ) : addFriendModal && historyCheck ?(
              <AddFriendModal 
                setAddFriendModal={setAddFriendModal} 
                setAddRequest={setAddRequest} 
                setAddFriendState={setAddFriendState}  
                checkState={checkState} 
                setCheckState={setCheckState} 
                setProfile={setProfile}
                setProfileModal={setProfileModal}
              />
            ) : managementFriendModal && historyCheck ?(
              <ManagementModal setManagementFriendModal={setManagementFriendModal} setProfile={setProfile} profileModal={profileModal} setProfileModal={setProfileModal}/>
              ) : (profileModal && profile?.userCd != null) ? (
                <FriendProfile profile={profile} setProfile={setProfile} contentState={contentState} setContentState={setContentState} setAddRequest={setAddRequest} setAddFriendState={setAddFriendState} profileAddCheck={profileAddCheck}  profileModal={profileModal} setProfileModal={setProfileModal}/>
                ) : (
                  <div className="contents-bg"></div>
          )}
          {addRequest && <AddRequest 
                          addFriendState={addFriendState} 
                          setAddRequest={setAddRequest} 
                          checkState={checkState} 
                          setCheckState={setCheckState} 
                          setProfileAddCheck={setProfileAddCheck}
                          viewCheck={viewCheck}
                          setViewCheck={setViewCheck}
                        />}
        </article>
      );

    case "2":
      return (
        <article className={`contents-wrap chat ${(openRoom && chat.currentChatRoomId)? "on" : ""}`}>
          {openRoom ? (
            <ChatRoomComponent setIsCreateModal={setIsCreateModal}  updateCheck={updateCheck} setUpdateCheck={setUpdateCheck}/>
          ) : (
              <div className="contents-bg"></div>
          )}
        </article>
      );

    case "3":
      return (
        <article className={`contents-wrap broadcast ${(openRoom &&fileState.currentFileID) || (openRoom && onCheck == "1") ? "on" : ""}`}>
          {openRoom? (
            <>
              <div className="contents-bg"></div>
              {loadingState && <div className="contents-room" 
                                    style={{
                                      position:"absolute", 
                                      backgroundColor:'gray', 
                                      zIndex:'100',
                                      opacity:'0.8',
                                      color:'white',
                                      fontSize:'24px',
                                      fontWeight:'bold'
                                    }}>파일 재생대기중...</div>}
              {onCheck === "2" && <ContentRoomComponent />}
              {onCheck === "1" && <ReplayContentRoomComponent audio={audio}/>}
            </>
          ): 
          (
            <div className="contents-bg"></div>
          )}
        </article>
      );

    default:
      break;
  }
};

export default ContentComponent;
